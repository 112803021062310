<template>
  <div class="plot">
    <div class="plot-html">
      <!-- toggle按钮 -->
      <div class="plot-list">
        <template v-for="(item, index) in poltList">
          <div
            v-if="item.type !== 'splitLine'"
            :key="item.type"
            class="plot-list-item"
          >
            <Button
              class="plot-list-item-btn"
              :class="currentPoltItemType === item.type ? 'active' : ''"
              :icon="item.icon"
              @click="handlePlotListItemClick(item.type)"
            >
              {{ item.name }}
              <!-- <i
                v-if="item.type === 'Polygon'"
                class="iconfont icon-arrow-down-filling"
                :class="currentPoltItemType === item.type ? 'up' : ''"
              ></i> -->
            </Button>
            <!-- 面面板 -->
            <!-- <div
              class="plot-list-item-panel"
              v-if="
                currentPoltItemType === 'Polygon' && item.type === 'Polygon'
              "
            >
              <Button
                class="plot-list-item-panel-btn"
                :class="currentPlotType === 'Polygon' ? 'active' : ''"
                direction="column"
                :icon="
                  currentPlotType === 'Polygon' ? 'plane' : 'plane-disabled'
                "
                @click="handlePlotBtnClick('Polygon')"
                >多边形</Button
              >
              <Button
                class="plot-list-item-panel-btn"
                :class="currentPlotType === 'Rectangle' ? 'active' : ''"
                direction="column"
                :icon="
                  currentPlotType === 'Rectangle' ? 'rect' : 'rect-disabled'
                "
                @click="handlePlotBtnClick('Rectangle')"
                >矩形</Button
              >
              <Button
                class="plot-list-item-panel-btn"
                :class="currentPlotType === 'Circle' ? 'active' : ''"
                direction="column"
                :icon="
                  currentPlotType === 'Circle' ? 'circle' : 'circle-disabled'
                "
                @click="handlePlotBtnClick('Circle')"
                >圆形</Button
              >
            </div> -->
          </div>
          <div v-else :key="index" class="plot-splitLine"></div>
        </template>
      </div>
      <!-- 样式管理 -->
      <DataclassAttrSytleEditDialog
        v-if="dataclassAttrSytleEditDialog.status && plotDialogType"
      />
      <!-- 标绘提示 -->
      <PlotTipDialog
        v-if="plotTipDialogVsible && plotTipDialogPosition"
        :info="plotTip"
        :position="plotTipDialogPosition"
      />
      <!-- 点弹窗 -->
      <PlotPointDialog
        ref="plotDialog"
        v-if="currentFeature && plotDialogType == 'Point'"
        :state="plotDialogState"
        :coordinates="currentFeature.coordinates"
        :detail="plotDetail"
        @createDataset="handleCreateDataset"
        @createDataclass="handleCreateDataclass"
        @coordChange="handleCoordChange"
        @updateIcon="handleIconUpdate"
        @stateChange="handleStateChange"
        @confirm="handleConfirm"
        @clear="clearPlot"
        @close="handleClose"
        @del="handleDel"
      />
      <!-- 线段弹窗 -->
      <PlotPolylineDialog
        ref="plotDialog"
        v-if="currentFeature && plotDialogType === 'Polyline'"
        :state="plotDialogState"
        :coordinates="currentFeature.coordinates"
        :detail="plotDetail"
        @createDataset="handleCreateDataset"
        @createDataclass="handleCreateDataclass"
        @stateChange="handleStateChange"
        @updatePolyline="handlePolylineUpdate"
        @clampToGroundChange="handleClampToGroundChange"
        @confirm="handleConfirm"
        @clear="clearPlot"
        @close="handleClose"
        @del="handleDel"
      />
      <!-- 多边形弹窗 -->
      <PlotPolygonDialog
        ref="plotDialog"
        v-if="currentFeature && plotDialogType === 'Polygon'"
        :state="plotDialogState"
        :coordinates="currentFeature.coordinates"
        :detail="plotDetail"
        @createDataset="handleCreateDataset"
        @createDataclass="handleCreateDataclass"
        @stateChange="handleStateChange"
        @updatePolygon="handlePolygonUpdate"
        @clampToGroundChange="handleClampToGroundChange"
        @confirm="handleConfirm"
        @clear="clearPlot"
        @close="handleClose"
        @del="handleDel"
      />
      <!-- 圆弹窗 -->
      <PlotCircleDialog
        ref="plotDialog"
        v-if="currentFeature && plotDialogType === 'Circle'"
        :state="plotDialogState"
        :coordinates="currentFeature.coordinates"
        :detail="plotDetail"
        @stateChange="handleStateChange"
        @clampToGroundChange="handleClampToGroundChange"
        @radiusChange="handleRadiusChange"
        @confirm="handleConfirm"
        @clear="clearPlot"
        @close="handleClose"
        @del="handleDel"
      />
      <!-- 新建数据集、数据类弹窗 -->
      <PlotAddDataclassDialog
        v-if="plotAddDataclassDialogVisible"
        :title="plotAddDataclassTitle"
        :datasetId="datasetId"
        :dataType="dataType"
        :dataclassType="dataclassType"
        @confirm="handleAddDataclass"
      />
    </div>
    <div class="plot-map">
      <!-- 绘制 -->
      <LongMapDraw v-if="draw" v-bind="draw" @drawed="handleDrawed" />
      <template v-if="showPointHeightDialog">
        <!-- 点位高度弹窗 -->
        <LongMapLabel
          v-for="(point, index) in points"
          :coordinates="point"
          :key="index"
          class="pointHeightLabel"
        >
          <PointHeightDialog
            :value="point[2]"
            @change="value => handlePointHeightChange(value, index)"
          />
        </LongMapLabel>
      </template>
      <LongMapLayer>
        <!-- 当前标绘元素 -->
        <template v-if="currentPlotFeature">
          <LongMapMark
            v-if="currentFeatureType === 'Point'"
            v-bind="currentPlotFeature"
          />
          <LongMapPolyline
            v-else-if="currentFeatureType === 'Polyline'"
            v-bind="currentPlotFeature"
          />
          <LongMapPolygon
            v-else-if="currentFeatureType === 'Polygon'"
            v-bind="currentPlotFeature"
          />
          <LongMapCircle
            v-else-if="currentFeatureType === 'Circle'"
            v-bind="currentPlotFeature"
          />
        </template>
      </LongMapLayer>
    </div>
  </div>
</template>

<script>
import $bus from 'p/utils';
import { mapState } from 'vuex';
import { ip } from 'p/urlSplice';
import PlotAddDataclassDialog from '../plot/PlotAddDataclassDialog';
import PointHeightDialog from '../plot/PointHeightDialog';
import PlotTipDialog from '../plot/PlotTipDialog';
import DataclassAttrSytleEditDialog from '../dataManager/DataclassAttrSytleEditDialog';
import PlotCircleDialog from '../plot/PlotCircleDialog';
import PlotPolygonDialog from '../plot/PlotPolygonDialog';
import PlotPolylineDialog from '../plot/PlotPolylineDialog';
import PlotPointDialog from '../plot/PlotPointDialog';
import Button from 'c/basic/Button';
import LongMapLayer from 'c/longmap/LongMapLayer';
import LongMapMark from 'c/longmap/LongMapMark';
import LongMapDraw from 'c/longmap/LongMapDraw';
import LongMapLabel from 'c/longmap/LongMapLabel';
import LongMapPolyline from 'c/longmap/LongMapPolyline';
import LongMapPolygon from 'c/longmap/LongMapPolygon';
import LongMapCircle from 'c/longmap/LongMapCircle';
import pointMark from 'a/map/point-mark.png';
import { longmapPoint, longmapColor } from 'c/longmap/Tool';
export default {
  inject: ['getMap'],
  name: 'MapPlot',
  components: {
    PlotAddDataclassDialog,
    PlotCircleDialog,
    PointHeightDialog,
    PlotTipDialog,
    DataclassAttrSytleEditDialog,
    PlotPolygonDialog,
    PlotPolylineDialog,
    PlotPointDialog,
    Button,
    LongMapLayer,
    LongMapMark,
    LongMapDraw,
    LongMapLabel,
    LongMapPolyline,
    LongMapPolygon,
    LongMapCircle
  },
  data() {
    return {
      // 绘制列表
      poltList: [
        { name: '点标注', icon: 'point', type: 'Point' },
        { type: 'splitLine' },
        { name: '线标注', icon: 'polyline', type: 'Polyline' },
        { type: 'splitLine' },
        { name: '面标注', icon: 'polygon', type: 'Polygon' }
      ],
      // 当前开启绘制项
      currentPoltItemType: '',
      // 当前标绘类型
      currentPlotType: '',
      // 标绘提示语
      plotTipDialogVsible: false,
      plotTipDialogPosition: '',
      // 绘制
      draw: '',
      // 当前标绘元素
      currentPlotFeature: '',
      // 点位高度
      showPointHeightDialog: false,
      // 当前选中元素
      currentFeature: '',
      currentFeatureType: '',
      points: [],
      // 弹窗状态
      plotDialogState: '',
      // 弹窗详情
      plotDetail: '',
      datasetId: '',
      dataType: '',
      dataclassType: ''
    };
  },
  computed: {
    // 标绘添加数据类弹窗标题
    plotAddDataclassTitle() {
      let title = '';
      const type = this.currentFeatureType;
      switch (type) {
        case 'Point':
          title = '新建单点数据类';
          break;
        case 'MultiPoint':
          title = '新建多点数据类';
          break;
        case 'Polyline':
          title = '新建单线数据类';
          break;
        case 'Polygon':
        case 'Rectangle':
        case 'Circle':
          title = '新建多边形数据类';
          break;
      }
      return title;
    },
    // 标绘操作提示
    plotTip() {
      let tip = '';
      const type = this.currentPlotType;
      switch (type) {
        case 'Point':
          tip = '点击左键开始标点，右键或【esc】键取消';
          break;
        case 'MultiPoint':
          tip = '点击左键开始标点，右键点位删除，点击取消按钮清空';
          break;
        case 'Polyline':
          tip = '点击左键继续画线，右键撤销上个节点，双击保存';
          break;
        case 'Polygon':
          tip = '点击绘制多边形，Esc键取消绘制，双击保存';
          break;
        case 'Rectangle':
          tip = '点击绘制矩形，Esc键取消绘制，双击保存';
          break;
        case 'Circle':
          tip = '点击绘制圆形，Esc键取消绘制，双击保存';
          break;
      }
      return tip;
    },
    // 绘制点状态
    plotPointState() {
      return (
        this.currentPlotType === 'Point' ||
        this.currentPlotType === 'MultiPoint'
      );
    },
    // 当前绘制对象uuid
    plotUuid: {
      get() {
        return this.$store.state.baseMap.plotUuid;
      },
      set(val) {
        this.$store.commit('baseMap/setPlotUuid', val);
      }
    },
    plotDialogType: {
      get() {
        return this.$store.state.plot.plotDialogType;
      },
      set(val) {
        this.$store.commit('plot/setPlotDialogType', val);
      }
    },
    plotAddDataclassDialogVisible: {
      get() {
        return this.$store.state.plot.plotAddDataclassDialogVisible;
      },
      set(val) {
        this.$store.commit('plot/setPlotAddDataclassDialogVisible', val);
      }
    },
    enabled: {
      get() {
        return this.$store.state.baseMap.enabled;
      },
      set(val) {
        this.$store.commit('baseMap/setEnabled', val);
      }
    },
    dataClassFeaturesData: {
      get() {
        return this.$store.state.data.dataClassFeaturesData;
      },
      set(val) {
        this.$store.commit('data/setDataClassFeaturesData', val);
      }
    },
    layerManagerData: {
      get() {
        return this.$store.state.layerData.layerManagerData;
      },
      set(val) {
        this.$store.commit('layerData/setLayerManagerData', val);
      }
    },
    intercation: {
      get() {
        return this.$store.state.baseMap.intercation;
      },
      set(val) {
        this.$store.commit('baseMap/setIntercation', val);
      }
    },
    ...mapState('data', ['dataclassAttrSytleEditDialog'])
  },
  created() {
    const vm = this;
    this.getMap().then(map => {
      // 监听地图点击事件
      map.addEventListener('click', function (e) {
        const point = e.point;
        const buttonType = e.button; // 0 —— 左键 2 —— 右键
        let { longitude, latitude, altitude: height } = point;
        longitude = parseFloat(longitude.toFixed(5));
        latitude = parseFloat(latitude.toFixed(5));
        height = parseFloat(height.toFixed(2));
        const feature = e.features[0];
        if (feature) {
          if (vm.intercation) return;
          const featureData = feature.featureData;
          if (!featureData) return;
          // 左键点击到元素
          if (buttonType === 0) {
            const { type, featureType, dialogData } = featureData;
            // 数据管理加载
            if (type === 'dataFeature') {
              const { id } = dialogData;
              vm.currentFeatureType = featureType;
              vm.checkDataManagerFeature({
                featureType,
                id,
                state: 'check',
                from: 'dataManager'
              });
            }
            // 图层管理
            if (type === 'layerManagerFeature') {
              const { id, layerId } = dialogData;
              vm.currentFeatureType = featureType;
              vm.checkDataManagerFeature({
                featureType,
                id,
                state: 'check',
                from: 'layerManager',
                layerId
              });
            }
          }
        } else {
          // 左键没有点击到元素
          if (buttonType === 0) {
            // 标绘点
            if (vm.plotPointState) {
              const coordinates = [longitude, latitude, height];
              const mark = {
                coordinates,
                icon: pointMark,
                show: true,
                featureData: {
                  uuid: vm.$uuid(),
                  type: 'plotPoint',
                  rightClickDel: true,
                  drag: true
                }
              };
              vm.currentPlotFeature = mark;
              vm.currentFeature = mark;
              vm.points = [coordinates];

              // 单点
              if (vm.currentPlotType === 'Point') {
                // 移除标绘按钮选中样式
                vm.currentPlotType = '';
                vm.currentPoltItemType = '';
                // 关闭标绘提示
                vm.plotTipDialogVsible = false;
                // 显示添加弹窗
                vm.currentFeatureType = 'Point';
                vm.plotDialogType = 'Point';
                vm.plotDialogState = 'add';
              }
            }
          }
        }
      });

      // 鼠标按下
      let featureDragState = false;
      map.addEventListener('mousedown', function (e) {
        const buttonType = e.button; // 0 —— 左键 2 —— 右键
        const feature = e.features[0];
        if (feature) {
          const featureData = feature.featureData;
          if (!featureData) return;
          const { drag } = featureData;
          // 左键点击到元素
          if (buttonType === 0) {
            // 可拖动
            if (drag) {
              featureDragState = true;
            }
          }
        }
      });
      // 鼠标移动
      map.addEventListener('mousemove', function (e) {
        const point = e.point;
        let { longitude, latitude, altitude: height } = point;
        longitude = parseFloat(longitude.toFixed(5));
        latitude = parseFloat(latitude.toFixed(5));
        height = parseFloat(height.toFixed(2));
        if (featureDragState) {
          // 禁止地图操作
          vm.enabled = false;
          // 更新元素位置
          vm.points[0] = vm.currentFeature.coordinates = [
            longitude,
            latitude,
            height
          ];
          // 修改鼠标指针样式
          const cursorDrag =
            process.env.BASE_URL + 'data/image/cursor-drag.ico';
          document.body.style.cursor = `url("${cursorDrag}"),default`;
        }
      });
      // 鼠标松开
      map.addEventListener('mouseup', function () {
        featureDragState = false;
        vm.enabled = true;
        document.body.style.cursor = 'default';
      });
    });

    // 标绘提示语随鼠标移动而移动
    document.body.addEventListener('mousemove', function (e) {
      if (vm.plotTipDialogVsible) {
        vm.plotTipDialogPosition = {
          top: e.pageY + 'px',
          left: e.pageX + 'px'
        };
      }
    });

    // 鼠标松开
    document.body.addEventListener('mouseup', function (e) {
      const { button } = e;
      // 右键
      if (button === 2) {
        // 标绘点时，右键结束标绘
        if (vm.currentPlotType === 'Point') {
          vm.currentPoltItemType = '';
          vm.quitPlot();
        }
      }
    });

    // 监听按键松开
    document.body.addEventListener('keyup', function (e) {
      const { keyCode } = e;
      switch (keyCode) {
        // esc
        case 27:
          vm.currentPoltItemType = '';
          vm.quitPlot();
          break;
      }
    });

    $bus.$on('plotFeature', data => {
      const { plotType, datasetId, dataclassId } = data;
      this.handlePlotBtnClick(plotType);
      this.plotDetail = { datasetId, classId: dataclassId };
    });
    // 属性表编辑时,打开编辑窗口
    $bus.$on('attributeListEdit', data => {
      // 判断是否正在交互，排除编辑元素
      if (
        !vm.intercation ||
        vm.currentFeature.edit ||
        vm.currentFeature.featureData.drag
      ) {
        // 查看
        if (data.state === 'check') {
          // 移除顶点高度弹窗
          vm.points = [];
          vm.showPointHeightDialog = false;
          // 退出交互、编辑
          vm.intercation = false;
          if (vm.currentFeature) {
            // 移除元素编辑状态
            vm.currentFeature.edit = false;
            vm.currentFeature.featureData.drag = false;
          }
        }
        if (data.state === 'edit') {
          vm.intercation = true;
          // 移除上一个元素编辑状态
          if (vm.currentFeature) {
            vm.currentFeature.edit = false;
            vm.currentFeature.featureData.drag = false;
          }
        }
        this.currentFeatureType = data.featureType;
        this.checkDataManagerFeature(data);
        // this.handleStateChange(data.state);
      } else return this.$message('正在交互中');
    });
    // 属性表数据删除时如有窗口,关闭对应的信息窗口
    $bus.$on('attributeListDel', ({ id, editState }) => {
      // 判断删除的元素是否在编辑中
      if (editState) {
        // 移除顶点高度弹窗
        vm.points = [];
        vm.showPointHeightDialog = false;
        // 退出交互、编辑
        vm.intercation = false;
      }
      if (this.plotDialogType) {
        this.plotDialogType =
          this.plotDetail.id == id ? '' : this.plotDialogType;
      }
    });
    $bus.$on('pointChange', ({ coordinate, index, type }) => {
      switch (type) {
        case 'add':
          this.points.splice(index, 0, coordinate);
          break;
        case 'remove':
          this.points.splice(index, 1);
          break;
        case 'set':
          this.points.splice(index, 1, coordinate);
          break;
      }
    });
    $bus.$on('quitPlot', () => {
      // 退出时，有元素在编辑中
      if (
        this.currentFeature &&
        (this.currentFeature.edit || this.currentFeature.featureData.drag)
      ) {
        this.currentFeature = '';
        this.intercation = false;
        this.points = [];
        this.showPointHeightDialog = false;
      }
      this.plotDialogType = '';
      this.plotDialogState = '';
    });
  },
  destroyed() {
    $bus.$off('quitPlot');
    $bus.$off('plotFeature');
    $bus.$off('attributeListEdit');
    $bus.$off('attributeListDel');
  },
  watch: {
    currentPlotType(type) {
      if (!type) {
        this.quitPlot();
      }
    },
    plotTipDialogVsible(visible) {
      if (!visible) {
        this.plotTipDialogPosition = '';
      }
    }
  },
  methods: {
    // 查看数据管理加载数据
    // featureType - 元素类型 Point Polyline Polygon
    // id - 元素id
    // state - check(查看)、edit(编辑)
    async checkDataManagerFeature({ featureType, id, state, from, layerId }) {
      const vm = this;
      // 获取详情
      let req = '';
      switch (featureType) {
        case 'Point':
          req = 'pointGet';
          break;
        case 'Polyline':
          req = 'lineGet';
          break;
        case 'Polygon':
          req = 'polygonGet';
          break;
      }

      // 获取要素详情
      const res = await vm.$service.feature[req]({ id });

      const {
        name,
        classId,
        datasetId,
        className: dataclassName,
        datasetName,
        customFieldJson
      } = res.data;

      // 筛选出当前元素对应数据
      let features = [];
      switch (from) {
        case 'dataManager':
          features = vm.dataClassFeaturesData[classId][featureType];
          break;
        case 'layerManager':
          features = vm.layerManagerData[layerId][featureType];
          break;
      }

      for (let i = 0; i < features.length; i++) {
        const feature = features[i];
        if (feature.featureData.uuid === id) {
          vm.currentFeature = feature;
          if (state == 'edit') {
            const coordinates = feature.coordinates;
            if (featureType === 'Point') {
              vm.points = [coordinates];
              vm.currentFeature.featureData.drag = true;
            } else {
              if (featureType === 'Polyline') {
                vm.points = [...coordinates];
                vm.showPointHeightDialog = true;
              }
              vm.currentFeature.edit = true;
            }
          }
          break;
        }
      }

      vm.plotDetail = {
        id,
        name,
        classId,
        datasetId,
        dataclassName,
        datasetName,
        customFieldJson,
        layerId
      };

      // 点
      if (featureType === 'Point') {
        vm.plotDetail.lng = res.data.lng;
        vm.plotDetail.lat = res.data.lat;
        vm.plotDetail.z = res.data.z;
        vm.plotDetail.iconId = res.data.iconId;
        vm.plotDetail.iconUrl = res.data.iconUrl
          ? ip.urlSplice(res.data.iconUrl)
          : '';
      }

      // 线
      if (featureType === 'Polyline') {
        const style = res.data.lineStyle || {};
        vm.plotDetail.styleResourcesId = style.id;
        vm.plotDetail.lineFillColor = style.fillColor;
      }

      // 多边形
      if (featureType === 'Polygon') {
        const style = res.data.polygonStyle || {};
        vm.plotDetail.styleResourcesId = style.id;
        vm.plotDetail.fillColor = style.fillColor;
        vm.plotDetail.frameFillColor = style.frameFillColor;
      }

      vm.plotDialogType = featureType;
      vm.plotDialogState = state;
    },
    // 更新多边形
    handlePolygonUpdate(color) {
      if (this.plotDetail.layerId) return;
      if (!color) {
        color = '#00A2E8B3';
      }
      const hex = color.slice(0, 7);
      const opacity = color.slice(7, 9)
        ? ((parseInt(color.slice(7, 9), 16) * (100 / 255)) / 100).toFixedNum(2)
        : 1;
      this.currentFeature.fillColor = [hex, opacity];
    },
    // 更新线段
    handlePolylineUpdate(colors) {
      if (this.plotDetail.layerId) return;
      if (!colors) {
        colors = ['#00A2E8FF'];
      }
      // 格式化颜色
      this.currentFeature.segmentColors = colors.map(color => {
        const hex = color.slice(0, 7);
        const opacity = color.slice(7, 9)
          ? ((parseInt(color.slice(7, 9), 16) * (100 / 255)) / 100).toFixedNum(
              2
            )
          : 1;
        return [hex, opacity];
      });
    },
    // 更新图标
    handleIconUpdate(url) {
      if (this.plotDetail.layerId) return;
      if (!url) {
        url = pointMark;
      }
      this.currentFeature.icon = url;
    },
    // 添加数据集、数据类
    handleAddDataclass(data) {
      this.plotAddDataclassDialogVisible = false;
      this.$refs.plotDialog.updateDataclass(data);
    },
    // 添加数据集
    handleCreateDataset({ dataType, dataclassType }) {
      this.datasetId = '';
      this.dataType = dataType;
      this.dataclassType = dataclassType;
      this.$store.commit('plot/setPlotAddDataclassDialogVisible', true);
    },
    // 添加数据类
    handleCreateDataclass({ datasetId, dataType, dataclassType }) {
      this.datasetId = datasetId;
      this.dataType = dataType;
      this.dataclassType = dataclassType;
      this.$store.commit('plot/setPlotAddDataclassDialogVisible', true);
    },
    // 半径改变
    handleRadiusChange(radius) {
      this.currentFeature.radius = radius;
    },
    // 高度改变
    handleCoordChange(coord) {
      this.currentFeature.coordinates = coord;
      if (this.currentFeatureType === 'Point') {
        this.points[0] = coord;
      }
    },
    // 修改元素贴地属性
    handleClampToGroundChange(state) {
      this.currentFeature.clampToGround = state;
    },
    // 输入框高度改变
    handlePointHeightChange(height, index) {
      const [lng, lat] = this.points[index];
      this.points[index] = [lng, lat, height];
      const currentFeatureType = this.currentFeatureType;
      if (currentFeatureType === 'Point') {
        this.currentFeature.coordinates = [...this.points[index]];
      }
      if (['Polyline', 'Polygon'].includes(currentFeatureType)) {
        this.currentFeature.coordinates = [...this.points];
      }
    },
    // 获取鼠标位置
    getPosition(map, coordinates) {
      let position = map.getOffsetByPoint(longmapPoint(coordinates));
      if (position) {
        position = { top: position.y + 'px', left: position.x + 'px' };
      } else {
        position = {};
      }
      return position;
    },
    // 关闭
    handleClose() {
      if (this.plotDialogType === 'Point') {
        this.currentFeature.featureData.drag = false;
      } else {
        this.currentFeature.edit = false;
      }
      this.plotDialogType = '';
      this.plotDialogState = '';
      this.currentFeature = '';
      this.intercation = false;
      this.showPointHeightDialog = false;
    },
    // 删除
    handleDel() {
      this.$confirm('确定删除该元素？', '删除数据', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'del'
      })
        .then(() => {
          // 删除
          let req = '';
          const plotDialogType = this.plotDialogType;
          const { featureData } = this.currentFeature;
          const { type, uuid, dialogData } = featureData;
          const { id, classId } = dialogData;
          let delFeatures = [];
          // 数据管理数据
          if (type === 'dataFeature') {
            switch (plotDialogType) {
              case 'Point':
                req = 'pointDel';
                delFeatures = this.dataClassFeaturesData[classId].Point;
                break;
              case 'Polyline':
                req = 'lineDel';
                delFeatures = this.dataClassFeaturesData[classId].Polyline;
                break;
              case 'Polygon':
                req = 'polygonDel';
                delFeatures = this.dataClassFeaturesData[classId].Polygon;
                break;
            }
          }
          // 图层管理
          if (type === 'layerManagerFeature') {
            const { layerId } = dialogData;
            switch (plotDialogType) {
              case 'Point':
                req = 'pointDel';
                delFeatures = this.layerManagerData[layerId].Point;
                break;
              case 'Polyline':
                req = 'lineDel';
                delFeatures = this.layerManagerData[layerId].Polyline;
                break;
              case 'Polygon':
                req = 'polygonDel';
                delFeatures = this.layerManagerData[layerId].Polygon;
                break;
            }
          }
          this.$service.feature[req]({ id }).then(res => {
            const { status, msg } = res;
            if (status === 200) {
              this.$message.success(msg);
              // 刷新属性表
              $bus.$emit('refreshAttrList', classId);
              // 删除元素
              const delIndex = delFeatures.findIndex(
                delFeature => delFeature.featureData.uuid === uuid
              );
              delFeatures.splice(delIndex, 1);
              if (plotDialogType === 'Point') {
                // 同时删除节点高度弹窗
                this.points.splice(delIndex, 1);
              }
              // 隐藏弹窗
              this.plotDialogType = '';
              this.plotDialogState = '';
            }
          });
        })
        .catch(() => {});
    },
    // 修改弹窗状态
    handleStateChange(state) {
      if (this.intercation) return this.$message('地图正在交互中');
      this.plotDialogState = state;
      if (state == 'edit') {
        const coordinates = this.currentFeature.coordinates;
        if (this.currentFeatureType === 'Point') {
          this.points = [coordinates];
          this.currentFeature.featureData.drag = true;
        } else {
          if (this.currentFeatureType === 'Polyline') {
            this.points = [...coordinates];
            this.showPointHeightDialog = true;
          }
          this.currentFeature.edit = true;
        }
        this.intercation = true;
      }
    },
    // 提交
    handleConfirm(data) {
      const { id, classId } = data;
      const type = this.currentFeatureType;
      // 编辑状态
      if (
        this.plotDialogState === 'edit' &&
        !this.currentFeature.featureData.dialogData.layerId
      ) {
        // 判断数据类位置是否改变
        const oldClassId = this.currentFeature.featureData.dialogData.classId;
        if (classId !== oldClassId) {
          this.dataClassFeaturesData[oldClassId][type].splice(
            this.dataClassFeaturesData[oldClassId][type].findIndex(
              feature => feature.featureData.uuid === id
            ),
            1
          );
          // 从老数据类中删除
          this.$store.commit('data/setDataClassFeaturesData', {
            key: oldClassId,
            val: { [type]: this.dataClassFeaturesData[oldClassId][type] }
          });

          // 移动到新数类
          let features = [];
          // 添加到数据管理中
          if (this.dataClassFeaturesData[classId]) {
            // 如果数据类中存在数据
            features = this.dataClassFeaturesData[classId][type];
          } else {
            // 不存在数据
            this.$set(this.dataClassFeaturesData, classId, {});
          }
          features.push(this.currentFeature);
          this.currentFeature.featureData.dialogData.classId = classId;
          this.$store.commit('data/setDataClassFeaturesData', {
            key: classId,
            val: { [type]: features }
          });
        }

        // 数据类更新
        let dataNeedUpdate = this.$store.state.data.dataNeedUpdate;
        if (!dataNeedUpdate.includes(classId)) {
          dataNeedUpdate.push(classId);
        }
        if (!dataNeedUpdate.includes(oldClassId)) {
          dataNeedUpdate.push(oldClassId);
        }
        this.$store.commit('data/setDataNeedUpdate', dataNeedUpdate);
      }
      // 添加状态
      if (this.plotDialogState === 'add') {
        // 点
        if (type === 'Point') {
          // 关闭编辑
          this.currentFeature.featureData.drag = false;
        }
        // 线
        if (type === 'Polyline') {
          // 关闭编辑
          this.currentFeature.edit = false;
        }
        // 面
        if (type === 'Polygon') {
          // 关闭编辑
          this.currentFeature.edit = false;
        }
        let features = [];
        // 添加到数据管理中
        if (this.dataClassFeaturesData[classId]) {
          // 如果数据类中存在数据
          features = this.dataClassFeaturesData[classId][type];
        } else {
          // 不存在数据
          this.$set(this.dataClassFeaturesData, classId, {});
        }
        this.currentFeature.featureData = {
          type: 'dataFeature',
          featureType: type,
          uuid: id,
          dialogData: {
            classId,
            id
          }
        };
        features.push(this.currentFeature);

        this.$store.commit('data/setDataClassFeaturesData', {
          key: classId,
          val: { [type]: features, remark: '未加载全部数据' }
        });

        // 数据类更新
        let dataNeedUpdate = this.$store.state.data.dataNeedUpdate;
        if (!dataNeedUpdate.includes(classId)) {
          dataNeedUpdate.push(classId);
          this.$store.commit('data/setDataNeedUpdate', dataNeedUpdate);
        }
      }
      this.currentPlotType = '';
      this.clearPlot();
    },
    // 绘制完成
    handleDrawed(e) {
      this.draw = '';
      this.plotTipDialogVsible = false;
      let coordinates = '';
      if (this.currentPlotType !== 'Circle') {
        coordinates = e.points.map(point => [
          point.longitude.toFixedNum(5),
          point.latitude.toFixedNum(5),
          point.altitude.toFixedNum(2)
        ]);
        if (this.currentPlotType === 'Polyline') {
          this.points = [...coordinates];
          this.showPointHeightDialog = true;
        }
      }

      // 添加标绘对象
      const uuid = this.$uuid();
      let type = '';
      let feature = '';
      let dialogData = '';
      // 标绘对象类型
      switch (this.currentPlotType) {
        case 'Polyline':
        case 'MultiPolyline':
          {
            type = 'Polyline';
            dialogData = {};
            feature = {
              type: 'segmentLine',
              coordinates,
              segmentColors: [['#00A2E8', 1]],
              show: true,
              edit: true,
              change: e => {
                const { type, index, point } = e;
                let coordinate = [];
                if (point) {
                  coordinate = [
                    point.longitude,
                    point.latitude,
                    point.altitude
                  ];
                }
                $bus.$emit('pointChange', { type, coordinate, index });
                switch (type) {
                  case 'add':
                    feature.coordinates.splice(index, 0, coordinate);
                    break;
                  case 'remove':
                    feature.coordinates.splice(index, 1);
                    break;
                  case 'set':
                    feature.coordinates[index] = coordinate;
                    break;
                }
              },
              featureData: {
                uuid,
                type: 'plotPolyline',
                rightClickDel: true,
                dialogData
              }
            };
            this.currentPoltItemType = '';
          }
          break;
        case 'Polygon':
        case 'Rectangle':
          {
            type = 'Polygon';
            dialogData = {};
            feature = {
              coordinates,
              fillColor: ['#00A2E8', 0.7],
              show: true,
              edit: true,
              change: e => {
                const { type, index, point } = e;
                let coordinate = [];
                if (point) {
                  coordinate = [
                    point.longitude,
                    point.latitude,
                    point.altitude
                  ];
                }
                $bus.$emit('pointChange', { type, coordinate, index });
                switch (type) {
                  case 'add':
                    feature.coordinates.splice(index, 0, coordinate);
                    break;
                  case 'remove':
                    feature.coordinates.splice(index, 1);
                    break;
                  case 'set':
                    feature.coordinates[index] = coordinate;
                    break;
                }
              },
              featureData: {
                uuid,
                type: 'plotPolygon',
                rightClickDel: true,
                dialogData
              }
            };
            this.currentPoltItemType = '';
          }
          break;
        case 'Circle':
          {
            type = 'Circle';
            let { radius, position } = e.feature;
            coordinates = [
              position.longitude.toFixedNum(6),
              position.latitude.toFixedNum(6),
              position.height.toFixedNum(2)
            ];
            radius = radius.toFixedNum(2);
            // 长度
            const area = (Math.PI * radius * radius).toFixedNum(2);
            // 高度，去所有节点里最高高度
            const height = Math.max(
              ...coordinates.map(coordinate => coordinate[2])
            ).toFixedNum(2);
            dialogData = {
              area,
              radius,
              height,
              clampToGround: true
            };
            feature = {
              coordinates,
              radius,
              clampToGround: true,
              edit: true,
              featureData: {
                uuid,
                type: 'plotCircle',
                rightClickDel: true,
                dialogData
              }
            };
          }
          break;
      }
      // 关闭标绘提示
      this.plotTipDialogVsible = false;
      // 移除标绘按钮选中样式
      this.currentPlotType = '';
      // 显示对应弹窗
      this.currentPlotFeature = feature;
      this.currentFeature = feature;
      this.currentFeatureType = type;
      this.plotDialogType = type;
      this.plotDialogState = 'add';
      this.plotDetail = { ...dialogData, ...this.plotDetail };
      // currentPlotType设置为空时，在watch里会调用方法关闭交互，需要重新打开交互
      this.$nextTick(() => {
        this.intercation = true;
      });
    },
    // 结束标绘
    quitPlot() {
      this.draw = '';
      this.currentPlotType = '';
      // 移除标绘操作提示弹窗
      this.plotTipDialogVsible = false;
      this.intercation = false;
    },
    // 清除绘制
    clearPlot() {
      this.quitPlot();
      // 移除标绘元素
      this.currentPlotFeature = '';
      // 移除元素信息弹窗
      this.plotDialogType = '';
      this.plotDialogState = '';
      // 移除高度弹窗
      this.showPointHeightDialog = false;
    },
    // 点击标绘二级按钮
    handlePlotBtnClick(type) {
      if (this.currentPlotType === type) {
        this.currentPlotType = '';
        this.quitPlot();
      } else {
        this.plotDetail = {};
        this.currentFeature = '';
        this.currentPlotType = type;
        this.plotTipDialogVsible = true;
        let draw = '';
        if (type === 'Polyline') {
          draw = {
            type: 'Polyline',
            material: new window.THREE.LineSegmentMaterial({
              transparent: true,
              linewidth: 3,
              segmentSize: 200,
              segmentColors: [longmapColor(['#00A2E8', 1])]
            })
          };
        }
        if (type === 'Polygon') {
          draw = {
            type: 'Polygon',
            material: longmapColor(['#00A2E8', 0.7])
          };
        }
        if (type === 'Rectangle') {
          draw = {
            type: 'Plane',
            rectangle: true
          };
        }
        if (type === 'Circle') {
          draw = {
            type: 'Circle'
          };
        }
        this.draw = draw;
        this.intercation = true;
      }
    },
    // 点击标绘一级按钮
    handlePlotListItemClick(type) {
      if (this.intercation) return this.$message('地图正在交互中');
      if (this.currentPoltItemType === type) {
        // 点、线时，移除标绘类型
        if (type === 'Point' || type === 'Polyline') {
          this.currentPlotType = '';
          this.quitPlot();
        }
        this.currentPoltItemType = '';
        this.clearPlot();
      } else {
        this.currentPoltItemType = type;
        // 点、线
        if (type === 'Point' || type === 'Polyline' || type === 'Polygon') {
          this.handlePlotBtnClick(type);
        } else {
          this.clearPlot();
          this.currentPlotType = '';
          this.quitPlot();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.plot {
  &-html {
    position: absolute;
    top: 10px;
    right: 252px;
  }
  // 标注列表
  &-list {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 6px;
    background-color: #fff;
    &-item {
      position: relative;
      &-btn {
        width: 110px;
        height: 34px;
        background-color: #fff;
        border-radius: 6px;
        font-size: 16px;
        color: #666;
        &.active {
          color: #507cff;
        }
        // 箭头
        .icon-arrow-down-filling {
          margin-left: 10px;
          font-size: 12px;
          transition: transform 200ms;
          &.up {
            transform: rotate(-180deg);
          }
        }
      }
      // 标注操作面板
      &-panel {
        display: flex;
        align-items: center;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 48px;
        padding: 0 10px;
        background: #ffffff;
        border: 1px solid #b5b5b5;
        border-radius: 8px;
        // 面板按钮
        &-btn {
          width: initial;
          height: 38px;
          padding: 0 10px;
          border-radius: 2px;
          background-color: #fff;
          color: #999;
          font-size: 14px;
          white-space: nowrap;
          /deep/ .icon {
            margin-right: 0;
            margin-bottom: 4px;
          }
          &.active {
            color: #507cff;
            background-color: #edf2ff;
          }
          &.cancel {
            color: #fe3530;
          }
          &.save {
            color: #1fd249;
          }
        }
        &-child {
          display: flex;
          align-items: center;
          position: absolute;
          top: 60px;
          left: 0;
          height: 48px;
          padding: 0 10px;
          background: #ffffff;
          border: 1px solid #b5b5b5;
          border-radius: 8px;
        }
        .plot-splitLine {
          height: 30px;
          margin: 0 10px;
        }
      }
    }
  }

  // 分割线
  &-splitLine {
    width: 1px;
    height: 15px;
    background: #999999;
  }
}

.pointHeightLabel {
  z-index: initial;
  margin-top: -14px;
}
</style>
