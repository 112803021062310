<template>
  <div class="longmap-urlTemplateImageryProvider"></div>
</template>

<script>
export default {
  name: 'LongMapUrlTemplateImageryProvider',
  inject: ['getMap'],
  props: {
    url: String
  },
  mounted() {
    this.addProvider();
  },
  destroyed() {
    this.removeProvider();
  },
  watch: {
    url() {
      this.removeProvider();
      this.addProvider();
    }
  },
  methods: {
    addProvider() {
      this.getMap().then(map => {
        this.map = map;
        const { url } = this;
        const provider = new window.UrlTemplateImageryProvider({
          url
        });
        this.provider = provider;
        map.addProvider(provider);
      });
    },
    removeProvider() {
      this.provider && this.map.removeProvider(this.provider);
    }
  }
};
</script>
