<template>
  <!-- 数据管理地图数据 -->
  <LongMapLayer>
    <template v-for="featuresDataObj in dataClassFeaturesData">
      <template v-for="(featuresData, type) in featuresDataObj">
        <template v-if="type === 'Point'">
          <LongMapMark
            v-for="feature in featuresData"
            :key="'dataClassPoint_' + feature.featureData.dialogData.id"
            v-bind="feature"
          />
        </template>
        <template v-if="type === 'Polyline'">
          <LongMapPolyline
            v-for="feature in featuresData"
            :key="'dataClassLine_' + feature.featureData.dialogData.id"
            v-bind="feature"
          />
        </template>
        <template v-if="type === 'Polygon'">
          <LongMapPolygon
            v-for="feature in featuresData"
            :key="'dataClassPlane_' + feature.featureData.dialogData.id"
            v-bind="feature"
          />
        </template>
      </template>
    </template>
    <!-- 视频数据类数据 -->
    <template v-for="(videoData, dataClassId) in dataClassVideoData">
      <LongMapMark
        v-for="mark in videoData"
        :key="'dataClassVideo_' + dataClassId + '_' + mark.featureData.id"
        v-bind="mark"
      />
    </template>
    <!-- 添加、编辑视频时的图标 -->
    <LongMapMark
      v-if="videoDataMark"
      v-bind="videoDataMark"
      :key="videoDataMark.featureData.uuid"
    />
  </LongMapLayer>
</template>

<script>
import LongMapLayer from 'c/longmap/LongMapLayer';
import LongMapMark from 'c/longmap/LongMapMark';
import LongMapPolyline from 'c/longmap/LongMapPolyline';
import LongMapPolygon from 'c/longmap/LongMapPolygon';
import { mapState } from 'vuex';
export default {
  name: 'MapDataManager',
  components: {
    LongMapPolygon,
    LongMapPolyline,
    LongMapMark,
    LongMapLayer
  },
  computed: {
    ...mapState('data', [
      'dataClassFeaturesData',
      'dataClassVideoData',
      'videoDataMark'
    ])
  }
};
</script>
