<template>
  <div class="longmap-mark">
    <slot></slot>
  </div>
</template>

<script>
import { longmapPoint } from './Tool';
export default {
  name: 'LongMapMark',
  inject: ['getLayer'],
  props: {
    // 坐标
    coordinates: {
      type: Array,
      required: true
    },
    icon: String,
    // 缩放
    scale: {
      type: Number,
      default: 1
    },
    // 锚点
    offset: {
      type: Object,
      default: () => {
        return {
          x: 0.5,
          y: 0
        };
      }
    },
    // 显示还是隐藏
    show: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    featureData: Object // 自定义数据
  },
  // 组件挂载后，添加元素
  mounted() {
    this.getLayer().then(layer => {
      this.layer = layer;
      // 参数
      const { icon, coordinates, scale, offset, featureData, show, zIndex } =
        this;
      // 创建元素
      let feature = new window.LongMap.Marker({
        url: icon,
        position: longmapPoint(coordinates),
        scale,
        offset,
        zIndex
      });
      feature.featureData = featureData;
      this.feature = feature;
      layer.add(feature);

      // 是否隐藏
      if (!show) {
        feature.show = false;
      }
    });
  },
  // 组件销毁后，移除元素
  destroyed() {
    this.feature && this.feature.remove();
  },
  // 监听属性变化
  watch: {
    coordinates(coordinates) {
      this.feature && this.feature.setPosition(longmapPoint(coordinates));
    },
    icon(icon) {
      this.feature && this.feature.setUrl(icon);
    },
    scale(scale) {
      this.feature && this.feature.setScale(scale);
    },
    offset(offset) {
      this.feature && this.feature.setOffset(offset);
    },
    show(show) {
      if (show && this.feature) {
        this.feature.show = true;
      } else {
        this.feature.show = false;
      }
    }
  }
};
</script>
