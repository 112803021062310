<template>
  <!-- 测量 -->
  <div class="measure">
    <!-- 测量页面相关元素 -->
    <div class="measure-html">
      <div class="measure-btn-wrap">
        <Button class="measure-toggle" icon="measure" @click="handleToggleClick"
          >测量工具</Button
        >
      </div>
      <div class="measure-list" v-if="toggle">
        <Button
          v-for="item in measureTypes"
          :key="item.type"
          class="measure-list-btn"
          :class="measureType === item.type ? 'active' : ''"
          direction="column"
          :icon="measureType === item.type ? item.activeIcon : item.icon"
          @click="measure(item.type)"
          >{{ item.name }}</Button
        >
      </div>
    </div>
    <!-- 测量地图相关元素 -->
    <div class="measure-map">
      <!-- 测量 -->
      <LongMapDraw
        v-if="measureDraw"
        v-bind="measureDraw"
        @add="e => measureClick(e, 1)"
        @remove="e => measureClick(e, 2)"
        @move="measureMove"
        @drawed="measureDrawed"
      />
      <!-- 测量结果图层 -->
      <LongMapLayer>
        <LongMapPolyline
          v-for="line in measureLines"
          v-bind="line"
          :key="line.featureData.uuid"
        />
        <LongMapPolygon
          v-for="polygon in measurePolygons"
          v-bind="polygon"
          :key="polygon.featureData.uuid"
        />
        <LongMapText
          v-for="text in currentMeasureTexts"
          v-bind="text"
          :key="text.featureData.uuid"
        />
        <LongMapText
          v-for="text in moveMeasureTexts"
          v-bind="text"
          :key="text.featureData.uuid"
        />
        <template v-for="texts in measureTexts">
          <LongMapText
            v-for="text in texts"
            v-bind="text"
            :key="text.featureData.uuid"
          />
        </template>
        <LongMapMark
          v-for="mark in measureMarks"
          v-bind="mark"
          :key="mark.featureData.uuid"
        />
      </LongMapLayer>
    </div>
  </div>
</template>

<script>
import LongMapDraw from 'c/longmap/LongMapDraw.vue';
import LongMapLayer from 'c/longmap/LongMapLayer.vue';
import LongMapMark from 'c/longmap/LongMapMark.vue';
import LongMapPolygon from 'c/longmap/LongMapPolygon.vue';
import LongMapPolyline from 'c/longmap/LongMapPolyline.vue';
import LongMapText from 'c/longmap/LongMapText.vue';
import closeMark from 'a/map/measureClearBtn.png';
import Button from 'c/basic/Button';
import { longmapColor, getLength, getArea } from 'c/longmap/Tool';
export default {
  name: 'MapMeasure',
  inject: ['getMap'],
  props: ['mapInfo'],
  components: {
    LongMapDraw,
    LongMapLayer,
    LongMapPolyline,
    LongMapPolygon,
    LongMapText,
    LongMapMark,
    Button
  },
  data() {
    return {
      toggle: false,
      measureTypes: [
        {
          name: '测高度',
          type: 'height',
          icon: 'measure-height',
          activeIcon: 'measure-height-active'
        },
        {
          name: '测距离',
          type: 'distance',
          icon: 'measure-distance',
          activeIcon: 'measure-distance-active'
        },
        {
          name: '测面积',
          type: 'area',
          icon: 'measure-area',
          activeIcon: 'measure-area-active'
        }
      ],
      measureType: '', // 测量类型
      measureDraw: '', // 测量绘制
      measureDrawPoints: [], // 测量绘制
      moveMeasureTexts: [],
      currentMeasureTexts: [], // 当前测量结果
      measureTexts: {}, // 测量结果
      measureLines: [], // 测距或测高绘制元素
      measurePolygons: [], // 测量面积绘制元素
      measureMarks: [] // 测量完成后的关闭图标
    };
  },
  computed: {
    intercation: {
      get() {
        return this.$store.state.baseMap.intercation;
      },
      set(val) {
        this.$store.commit('baseMap/setIntercation', val);
      }
    }
  },
  created() {
    const vm = this;
    this.getMap().then(map => {
      this.map = map;
      map.addEventListener('click', e => {
        const feature = e.features[0];
        if (feature) {
          const featureData = feature.featureData;
          if (featureData) {
            // 测量工具删除操作
            if (featureData.type === 'measureClearBtn') {
              const uuid = featureData.measureUUID;
              const measureType = featureData.measureType;
              if (measureType === 'area') {
                // 移除面
                this.measurePolygons = this.measurePolygons.filter(item => {
                  return item.featureData.uuid !== uuid;
                });
              } else {
                // 移除线
                this.measureLines = this.measureLines.filter(item => {
                  return item.featureData.uuid !== uuid;
                });
              }
              // 移除关闭按钮
              this.measureMarks = this.measureMarks.filter(item => {
                return item.featureData.uuid !== featureData.uuid;
              });
              // 移除测量结果文字
              delete this.measureTexts[uuid];
            }
          }
        }
      });
    });

    // 监听按键松开
    document.body.addEventListener('keyup', function (e) {
      const { keyCode } = e;
      switch (keyCode) {
        // esc
        case 27:
          vm.clearMeasure();
          break;
      }
    });
  },
  methods: {
    // 测量完成
    measureDrawed() {
      // 添加元素到测量图层
      const measureType = this.measureType; // 测量类型
      const uuid = this.$uuid();
      const measureDrawPoints = this.measureDrawPoints;
      switch (measureType) {
        // 测距、测高以线的形式添加
        case 'distance': {
          let line = {
            coordinates: measureDrawPoints.map(point => [
              point.longitude,
              point.latitude,
              point.altitude
            ]),
            featureData: {
              uuid
            }
          };

          this.measureLines.push(line);
          let lastMeasureText = this.moveMeasureTexts.pop();
          const { longitude, latitude, altitude } =
            measureDrawPoints[measureDrawPoints.length - 1];
          lastMeasureText.coordinates = [longitude, latitude, altitude];
          this.currentMeasureTexts.push(lastMeasureText);
          break;
        }
        case 'height': {
          const line = {
            coordinates: measureDrawPoints.map(point => [
              point.longitude,
              point.latitude,
              point.altitude
            ]),
            featureData: {
              uuid
            }
          };
          this.measureLines.push(line);
          this.currentMeasureTexts.push(this.moveMeasureTexts.pop());
          break;
        }
        // 测面积以面的形式添加
        case 'area': {
          let plane = {
            coordinates: measureDrawPoints.map(point => [
              point.longitude,
              point.latitude,
              point.altitude
            ]),
            featureData: {
              uuid
            }
          };
          this.measurePolygons.push(plane);

          this.currentMeasureTexts.push(this.moveMeasureTexts.pop());
          break;
        }
      }
      // 添加测量关闭按钮
      this.measureMarks.push({
        coordinates: [
          measureDrawPoints[measureDrawPoints.length - 1].longitude,
          measureDrawPoints[measureDrawPoints.length - 1].latitude,
          measureDrawPoints[measureDrawPoints.length - 1].altitude
        ],
        icon: closeMark,
        offset: { x: 1, y: 0 },
        featureData: {
          type: 'measureClearBtn',
          measureType,
          measureUUID: uuid,
          uuid: this.$uuid()
        }
      });
      this.measureTexts[uuid] = this.currentMeasureTexts;
      this.currentMeasureTexts = [];
      this.moveMeasureTexts = [];
      // 移除测量绘制对象
      this.measureDraw = '';
      this.measureType = '';
      this.measureDrawPoints = [];
      // 关闭交互状态
      this.intercation = false;
    },
    // 测量过程中，拖动
    measureMove(e) {
      // 测量结果跟随鼠标移动
      let measureResult = this.getMeasureResult(e);
      const position = e.point;
      if (!measureResult) return;
      if (!this.moveMeasureTexts.length) {
        this.moveMeasureTexts = [
          {
            text: ' ',
            color: ['#ffffff', 1],
            backgroundColor: ['#bf5b16', 1],
            coordinates: [],
            offset: { x: 0, y: 0 },
            featureData: {
              uuid: this.$uuid()
            }
          }
        ];
      }
      // 更具测量结果字符串来设置偏移量
      // 更新测量结果
      this.moveMeasureTexts[this.moveMeasureTexts.length - 1].text =
        measureResult;
      this.moveMeasureTexts[this.moveMeasureTexts.length - 1].show = true;
      // 更新结果位置，跟随鼠标
      this.moveMeasureTexts[this.moveMeasureTexts.length - 1].coordinates = [
        position.longitude,
        position.latitude,
        position.altitude
      ];
    },
    // 测量过程中 点击
    measureClick(e, button) {
      if (!this.measureDraw) return;
      // 右键删除上一个测量结果
      if (button === 2) {
        // 删除最后一个点位
        this.measureDrawPoints.pop();
        // 在测距过程中删除节点时要同时删除对应的测量结果
        if (this.measureType === 'distance') {
          // 删除倒数第二个结果
          this.currentMeasureTexts.splice(-1, 1);
        }
        if (this.measureDrawPoints.length > 0) {
          let measureResult = this.getMeasureResult();
          if (measureResult) {
            // 更新当结果
            const measureText =
              this.moveMeasureTexts[this.moveMeasureTexts.length - 1];
            if (measureText) {
              measureText.text = measureResult;
            }
          } else {
            // 无测量结果，清空之前添加的所有结果
            this.moveMeasureTexts = [];
          }
        } else {
          this.moveMeasureTexts = [];
        }
        return;
      }
      this.measureDrawPoints.push(e.point);
      if (this.measureType === 'distance') {
        // 测距时候点击的添加测量结果
        if (this.measureDrawPoints.length < 2) return;
        let measureResult = this.getMeasureResult();
        this.currentMeasureTexts.push({
          text: measureResult,
          color: ['#ffffff', 1],
          backgroundColor: ['#bf5b16', 1],
          coordinates: [e.point.longitude, e.point.latitude, e.point.altitude],
          offset: { x: 0, y: 0 },
          featureData: {
            uuid: this.$uuid()
          }
        });
      }
    },
    // 获取测量结果
    getMeasureResult(e) {
      let points = this.measureDrawPoints;
      if (!points || points.length < 1) {
        return;
      }
      if (e) {
        // 拼接前面固定点和最后一个活动点
        points = points.concat(e.point);
      }
      const measureType = this.measureType;
      let measureResult = '';
      switch (measureType) {
        case 'distance':
          measureResult = getLength(points).toFixed(2) + 'm';
          break;
        case 'height':
          measureResult =
            Math.abs(points[0].altitude - points[1].altitude).toFixed(2) + 'm';
          break;
        case 'area':
          {
            measureResult =
              (points.length >= 3 ? getArea(points).toFixed(2) : '0') + '㎡';
          }
          break;
      }
      return measureResult;
    },
    // 移除测量
    clearMeasure() {
      this.measureType = '';
      this.measureDraw = '';
      this.measureDrawPoints = [];
      this.moveMeasureTexts = [];
      this.currentMeasureTexts = [];
      this.intercation = false;
    },
    // 测距
    measure(type) {
      if (this.measureType === type) {
        this.clearMeasure();
        return;
      }
      // 判断地图是否在交互中
      if (this.intercation) return this.$message('地图正在交互中');
      this.measureType = type;
      let info = '';
      this.measureDraw = '';
      this.$nextTick(() => {
        let measureDraw = '';
        // 根据测量类型，初始化测量绘制绘制对象
        switch (type) {
          case 'distance':
            info = '单击鼠标左键，添加测量节点，添加最后节点时双击结束测量';
            measureDraw = {
              type: 'Polyline',
              material: new window.THREE.LineSegmentMaterial({
                transparent: true,
                linewidth: 3,
                segmentSize: 200,
                segmentColors: [longmapColor(['#00A2E8', 1])]
              })
            };
            break;
          case 'height':
            info = '单击测量起点和终点，结束测量';
            measureDraw = {
              type: 'Polyline',
              material: new window.THREE.LineSegmentMaterial({
                transparent: true,
                linewidth: 3,
                segmentSize: 200,
                segmentColors: [longmapColor(['#00A2E8', 1])]
              }),
              maxPoint: 2
            };
            break;
          case 'area':
            info = '单击添加测面积顶点，添加最后顶点时双击结束测量';
            measureDraw = {
              type: 'Polygon',
              material: longmapColor(['#00A2E8', 0.7])
            };
            break;
        }
        this.$message({ message: info, duration: 5000 });
        this.measureDraw = measureDraw;
        this.intercation = true;
      });
    },
    handleToggleClick() {
      this.toggle = !this.toggle;
      if (!this.toggle) {
        this.measureType = '';
        this.measureDraw = '';
        this.measureDrawPoints = [];
      }
    }
  }
};
</script>

<style lang="less" scoped>
.measure {
  &-html {
    position: absolute;
    top: 10px;
    right: 136px;
    user-select: none;
  }
  &-toggle {
    width: 106px;
    height: 34px;
    background: #ffffff;
    border-radius: 6px;
    color: #666;
    font-size: 16px;
  }
  &-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    padding: 0 10px;
    background: #ffffff;
    border: 1px solid #b5b5b5;
    border-radius: 8px;
    &-btn {
      width: initial;
      height: 38px;
      padding: 0 10px;
      border-radius: 2px;
      background-color: #fff;
      color: #999;
      font-size: 14px;
      white-space: nowrap;
      /deep/ .icon {
        margin-right: 0;
        margin-bottom: 4px;
      }
      &.active {
        color: #507cff;
        background-color: #edf2ff;
      }
    }
  }
}
</style>
