<template>
  <div class="longmap-label" :style="position">
    <slot></slot>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import { longmapPoint } from './Tool';
export default {
  name: 'LongMapLabel',
  inject: ['getMap'],
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      position: {}
    };
  },
  mounted() {
    this.getMap().then(map => {
      this.map = map;
      this.position = this.getPosition(map, this.coordinates);
      map.addEventListener('change', () => {
        this.position = this.getPosition(map, this.coordinates);
      });

      const ro = new ResizeObserver(entries => {
        entries.forEach(() => {
          this.position = this.getPosition(map, this.coordinates);
        });
      });
      // 监听容器尺寸变化，更新坐标
      ro.observe(map.container);
    });
  },
  watch: {
    // 坐标更新时，更新屏幕位置
    coordinates(newCoordinates) {
      this.position = this.getPosition(this.map, newCoordinates);
    }
  },
  methods: {
    getPosition(map, coordinates) {
      let position = map.getOffsetByPoint(longmapPoint(coordinates));
      if (position) {
        position = { top: position.y + 'px', left: position.x + 'px' };
      } else {
        position = {};
      }
      return position;
    }
  }
};
</script>

<style lang="less" scoped>
.longmap-label {
  position: absolute;
  z-index: 999;
  transform: translate(-50%, -100%);
  transition: all 50ms;
}
</style>
