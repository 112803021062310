<template>
  <div class="longmap-draw"></div>
</template>

<script>
export default {
  name: 'LongMapDraw',
  inject: ['getMap'],
  props: {
    type: {
      type: String,
      required: true
    },
    material: [String, Object],
    maxPoint: Number
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.remove();
  },
  watch: {
    type() {
      this.remove();
      this.init();
    }
  },
  methods: {
    init() {
      let vm = this;
      this.getMap().then(map => {
        this.map = map;
        const { type, material, maxPoint } = this;
        let option = {
          type,
          material
        };
        if (maxPoint) {
          option.maxPoint = maxPoint;
        }
        // 添加绘制对象
        let draw = new window.LongMap.Draw(option);
        this.draw = draw;
        map.addIntercation(draw);
        // 暴露添加事件
        draw.addEventListener('add', function (e) {
          vm.$emit('add', e);
        });
        // 暴露移除事件
        draw.addEventListener('remove', function (e) {
          vm.$emit('remove', e);
        });
        // 暴露拖拽事件
        draw.addEventListener('move', function (e) {
          vm.$emit('move', e);
        });
        // 暴露绘制完成事件
        draw.addEventListener('drawed', function (e) {
          vm.$emit('drawed', e);
        });
      });
    },
    remove() {
      this.draw && this.map.removeIntercation(this.draw);
    }
  }
};
</script>
