<template>
  <Button
    class="mapFullScreenBtn"
    :icon="fullScreen ? 'cancelFullScreen' : 'fullScreen'"
    @click="handleClick"
    >{{ fullScreen ? '取消全屏' : '全屏显示' }}</Button
  >
</template>

<script>
import Button from 'c/basic/Button.vue';
export default {
  name: 'MapFullScreenBtn',
  components: {
    Button
  },
  computed: {
    fullScreen: {
      get() {
        return this.$store.state.baseMap.fullScreen;
      },
      set(val) {
        this.$store.commit('baseMap/setFullScreen', val);
      }
    }
  },
  methods: {
    handleClick() {
      this.fullScreen = !this.fullScreen;
    }
  }
};
</script>

<style lang="less" scoped>
.mapFullScreenBtn {
  width: 106px;
  height: 34px;
  background: #ffffff;
  border-radius: 6px;
  color: #666;
  font-size: 16px;
}
</style>
