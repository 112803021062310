<template>
  <!-- 右键菜单 -->
  <ul class="contextmenu" ref="contextmenu">
    <li
      class="contextmenu-item"
      v-for="(item, index) in data"
      :key="index"
      :class="{ splitLine: item.type === 'splitLine' }"
      @click="menuClick(item)"
      @mouseenter="StopRoll(item)"
      @mouseleave="UpRoll(item)"
    >
      <!-- 默认显示文字和图标 -->
      <template v-if="!item.type">
        <div
          class="contextmenu-item-left"
          :class="item.disabled ? 'disabled' : ''"
        >
          <i
            class="icon icon-menu contextmenu-item-icon"
            :class="'icon-menu-' + item.icon"
          ></i>
          {{ item.label }}
        </div>
      </template>
      <i class="iconfont icon-arrow-right-filling" v-if="item.rightIcon"></i>
      <ul
        class="contextmenu-item-children"
        v-if="item.rightIcon && item.childShow"
      >
        <li
          v-for="(i, index) in item.children"
          :key="index"
          @click="childrenClick(i)"
        >
          {{ i.label }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    data: { type: Array, default: () => [] },
    position: { type: Object, default: () => {} }
  },
  data() {
    return {
      childShow: false
    };
  },
  watch: {
    position(val) {
      this.$nextTick(() => {
        const dom = this.$refs.contextmenu;
        // 菜单高度
        const domHeight = dom.offsetHeight;
        // 菜单宽度
        const domWidth = dom.offsetWidth;
        // 菜单距离页面顶部距离
        const domTop = val.top;
        // 菜单距离页面左边距离
        const domLeft = val.left;
        // 页面高度
        const bodyHieght = window.innerHeight;
        // 页面宽度
        const bodyWidth = window.innerWidth;
        // 判断菜单是否超出屏幕之外
        if (bodyHieght < domHeight + domTop) {
          dom.style.top = domTop - domHeight + 'px';
        } else {
          dom.style.top = domTop + 'px';
        }
        if (bodyWidth < domWidth + domLeft) {
          dom.style.left = domLeft - domWidth + 'px';
        } else {
          dom.style.left = domLeft + 'px';
        }
      });
    }
  },
  methods: {
    menuClick(data) {
      const _this = this;
      // 判断是否禁用
      if (!data.disabled) {
        data.fn && data.fn(_this, data);
      }
    },
    childrenClick(data) {
      data.fn && data.fn(this, data);
    },
    StopRoll(data) {
      if (data.rightIcon) {
        data.childShow = true;
      }
    },
    UpRoll(data) {
      if (data.rightIcon) {
        data.childShow = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 右键菜单
.contextmenu {
  width: 156px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(51, 51, 51, 0.2),
    0px -4px 8px 0px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  z-index: 999;
  font-size: 16px;
  // 菜单项
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 10px 0 20px;
    line-height: 32px;
    position: relative;
    cursor: pointer;
    &-children {
      position: absolute;
      right: -118px;
      top: 0;
      width: 116px;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      font-size: 14px;
      li {
        text-align: center;
        // 悬浮
        &:hover {
          background-color: #e3ecff;
        }
        // 最后一项
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    // 悬浮
    &:hover {
      background-color: #e3ecff;
    }
    // 最后一项
    &:last-child {
      margin-bottom: 0;
    }
    // 分割线
    &.splitLine {
      height: 1px;
      background-color: #e5e5e5;
    }
    // 图标
    &-icon {
      margin-right: 10px;
    }
  }
}
.disabled {
  opacity: 0.5;
}
</style>
