<template>
  <!-- 地图信息 -->
  <div class="mapinfo">
    <div class="mapinfo-bottom" v-if="mapInfo">
      <!-- 经度 -->
      <span v-if="mapLngVisible">经度：{{ mapInfo.longitude }}</span>
      <div v-if="mapLngVisible" class="mapinfo-bottom-border" />
      <!-- 纬度 -->
      <span v-if="mapLatVisible">纬度：{{ mapInfo.latitude }}</span>
      <div v-if="mapLatVisible" class="mapinfo-bottom-border" />
      <!-- 旋转角度 -->
      <span v-if="mapHeadingVisible">旋转角度：{{ mapInfo.heading }}</span>
      <div v-if="mapHeadingVisible" class="mapinfo-bottom-border" />
      <!-- 俯视角度 -->
      <span v-if="mapPitchVisible">俯视角度：{{ mapInfo.pitch }}</span>
      <div v-if="mapPitchVisible" class="mapinfo-bottom-border" />
      <!-- 高度 -->
      <span v-if="mapHeightVisible">高度：{{ mapInfo.height }}</span>
      <!-- 比例尺 -->
    </div>
    <div
      v-if="mapInfo && mapScaleRulerVisible"
      class="mapinfo-bottom-scaleRuler"
      :style="{ width: mapInfo.mapScaleRuler.width + 'px' }"
    >
      <p style="margin-top: -12px">{{ mapInfo.mapScaleRuler.distance }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Mapinfo',
  props: ['mapInfo'],
  computed: {
    ...mapState('baseMap', [
      'mapLngVisible',
      'mapLatVisible',
      'mapHeadingVisible',
      'mapPitchVisible',
      'mapHeightVisible',
      'mapScaleRulerVisible'
    ])
  }
};
</script>

<style lang="less" scoped>
.mapinfo {
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  height: 30px;
  background: rgba(37, 43, 72, 0.6);
  color: rgba(220, 220, 220, 0.729);
  &-bottom {
    position: absolute;
    right: 140px;
    line-height: 30px;
    span {
      margin: 0 20px;
      height: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    &-border {
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #999999;
    }
    // 比例尺
    &-scaleRuler {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 20px;
      height: 5px;
      padding-bottom: 5px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      border: 1px solid #fff;
      border-top: none;
    }
  }
}
</style>
