<template>
  <div class="longmap-polygon">
    <slot></slot>
  </div>
</template>

<script>
import { longmapPoint, longmapColor } from './Tool';
export default {
  name: 'LongMapPolygon',
  inject: ['getLayer'],
  props: {
    coordinates: {
      type: Array,
      required: true
    },
    fillColor: {
      type: Array,
      default: () => ['#00A2E8', 0.7]
    },
    borderColor: {
      type: Array,
      default: () => ['#00A2E8', 1]
    },
    borderWidth: {
      type: Number,
      default: 3
    },
    // 编辑
    edit: Boolean,
    // 显示
    show: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    change: Function, // 编辑回调事件
    // 自定义数据
    featureData: Object
  },
  mounted() {
    this.init();
  },
  // 组件销毁时，移除元素
  destroyed() {
    this.remove();
  },
  methods: {
    init() {
      this.getLayer().then(layer => {
        const { coordinates, fillColor, edit, show, zIndex, featureData } =
          this;
        let feature = new window.LongMap.Polygon({
          points: longmapPoint(coordinates),
          zIndex,
          material: longmapColor(fillColor)
        });
        feature.featureData = featureData;
        layer.add(feature);
        this.feature = feature;
        // 是否开启编辑
        if (edit) {
          this.onEdit();
        }
        // 是否隐藏
        if (!show) {
          feature.show = false;
        }
      });
    },
    remove() {
      if (this.feature) {
        if (this.edit) {
          this.offEdit();
        }
        this.feature.remove();
      }
    },
    onEdit() {
      const { feature, change } = this;
      feature.edit = true;
      feature.change = change;
    },
    offEdit() {
      const { feature } = this;
      feature.edit = false;
      feature.change = undefined;
    },
    setStyle() {
      const { fillColor } = this;
      let material = longmapColor(fillColor);
      this.feature.setMaterial(material);
    }
  },
  watch: {
    coordinates() {
      this.remove();
      this.init();
    },
    fillColor() {
      this.setStyle();
    },
    borderColor() {
      this.setStyle();
    },
    borderWidth() {
      this.setStyle();
    },
    edit(edit) {
      if (edit) {
        this.onEdit();
      } else {
        this.offEdit();
      }
    },
    show(show) {
      let feature = this.feature;
      if (show) {
        feature.show = true;
      } else {
        feature.show = false;
      }
    }
  }
};
</script>
