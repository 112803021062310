<template>
  <div class="pointHeightDialog">
    <!-- 底部三角 -->
    <div class="arrow-border-btoom"></div>
    <div class="pointHeightDialog-content">
      <p class="pointHeightDialog-content-prefix">H:</p>
      <input
        type="text"
        class="pointHeightDialog-content-height"
        :value="value"
        @change="$event => $emit('change', parseFloat($event.target.value))"
      />
      <p class="pointHeightDialog-content-suffix">m</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointHeightDialog',
  model: {
    event: 'change'
  },
  props: {
    value: Number
  }
};
</script>

<style lang="less" scoped>
.pointHeightDialog {
  position: relative;
  padding: 5px 12px;
  border-radius: 8px;
  border: 1px solid #7f8ed5;
  background-color: rgba(37, 43, 72, 0.8);
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    &-prefix {
      font-size: 18px;
    }
    &-height {
      box-sizing: border-box;
      width: 50px;
      height: 30px;
      margin-left: 4px;
      margin-right: 8px;
      line-height: 30px;
      border-radius: 2px;
      background-color: rgba(195, 210, 255, 0.6);
      text-align: center;
    }
  }
}

// 带边框箭头
// 两种颜色箭头叠加形成
.arrow-border-btoom {
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-color: #7f8ed5 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 8px;
  border-bottom: none;
  &::after {
    content: ' ';
    position: absolute;
    top: -6px;
    right: -7px;
    border-color: rgba(37, 43, 72, 0.8) transparent transparent transparent;
    border-style: solid;
    border-width: 5px 7px;
    border-bottom: none;
  }
}
</style>
