<template>
  <div class="longmap-layer">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'LongMapLayer',
  inject: ['getMap'],
  provide() {
    return {
      getLayer: this.getLayer
    };
  },
  props: {
    // 聚合属性
    cluster: Object,
    // 显示还是隐藏
    show: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.getMap().then(map => {
      this.map = map;
      const { cluster, show } = this;
      let layer = new window.THREE.Group();
      map.scene.add(layer);
      this.layer = layer;
      // 聚合属性
      if (cluster) {
        layer.setCluster();
      }
      // 判断图层显示隐藏
      if (!show) {
        layer.hide();
      }
    });
  },
  // 组件销毁后，移除所有元素
  destroyed() {
    const { map, layer } = this;
    layer && map.scene.remove(layer);
  },
  watch: {
    // 图层显示隐藏
    show(show) {
      if (!this.layer) {
        throw new Error('图层未初始化');
      }
      if (show) {
        this.layer.show();
      } else {
        this.layer.hide();
      }
    }
  },
  methods: {
    getLayer() {
      return new Promise(resolve => {
        let layer = this.layer;
        if (layer) {
          resolve(layer);
        } else {
          // 定时获取图层对象
          let timer = setInterval(() => {
            let layer = this.layer;
            if (!layer) return;
            // 移除定时
            clearInterval(timer);
            resolve(layer);
          }, 500);
        }
      });
    }
  }
};
</script>
