<template>
  <div class="plotTipDialog" :style="{ ...position }">
    <div class="arrow-border-left"></div>
    <slot>{{ info }}</slot>
  </div>
</template>

<script>
export default {
  name: 'PlotTipDialog',
  props: {
    info: String,
    position: [String, Object]
  }
};
</script>

<style lang="less" scoped>
.plotTipDialog {
  position: fixed;
  z-index: 1;
  transform: translate(15px, -50%);
  height: 43px;
  padding: 0 15px;
  line-height: 43px;
  border-radius: 8px;
  border: 1px solid #7f8ed5;
  background-color: rgba(37, 43, 72, 0.8);
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
}

// 带边框箭头
// 两种颜色箭头叠加形成
.arrow-border-left {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: transparent #7f8ed5 transparent transparent;
  border-style: solid;
  border-width: 8px 10px;
  border-left: none;
  &::after {
    content: ' ';
    position: absolute;
    top: -6px;
    right: -11px;
    border-color: transparent rgba(37, 43, 72, 0.8) transparent transparent;
    border-style: solid;
    border-width: 6px 8px;
    border-left: none;
  }
}
</style>
