<template>
  <Dialog
    :title="title"
    width="22.39583vw"
    :position="{
      top: '9.89583vw',
      right: '11.19792vw',
      left: 'initial',
      transform: 'initial'
    }"
    @close="cancel"
  >
    <el-form
      class="form"
      label-position="left"
      label-width="5vw"
      :model="form"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="名称：" prop="name">
        <p v-if="state === 'check'">{{ detail.name }}</p>
        <el-input v-else v-model="form.name" />
      </el-form-item>
      <el-form-item label="位置：" prop="dataclassId">
        <p v-if="state === 'check'">
          {{ detail.datasetName }}——{{ detail.dataclassName }}
        </p>
        <DataclassSelect
          v-else
          v-model="form.dataclassId"
          @change="handleDataclassSelectChange"
        />
      </el-form-item>
      <el-form-item label="面积：">
        <p>{{ detail.area }}㎡</p>
      </el-form-item>
      <el-form-item label="贴地：">
        <p v-if="state === 'check'">{{ detail.clampToGround ? '是' : '否' }}</p>
        <el-checkbox
          v-else
          v-model="detail.clampToGround"
          @change="checked => $emit('clampToGroundChange', checked)"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="圆心：">
        <p v-if="state === 'check'">{{ detail.lng }} {{ detail.lat }}</p>
        <div v-else class="form-content">
          <template v-if="coordEditState">
            <!-- 左侧输入框 -->
            <el-input
              class="ipt"
              v-model="form.coord"
              @change="handleCoordChange"
            />
            <!-- 右侧按钮 -->
            <div class="form-content-right">
              <button
                type="button"
                class="editBtn confirm"
                @click="confirmCoordChange"
              >
                <i class="icon icon-gou"></i>
              </button>
              <button
                type="button"
                class="editBtn cancel"
                @click="cancelCoordEdit"
              >
                <i class="icon icon-cha"></i>
              </button>
            </div>
          </template>
          <template v-else>
            <p>{{ form.coord }}</p>
            <button type="button" @click="editCoord">
              <i class="icon icon-edit-2"></i>
            </button>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="半径：">
        <p v-if="state === 'check'">{{ detail.radius }}</p>
        <div v-else class="form-content">
          <template v-if="radiusEditState">
            <!-- 左侧输入框 -->
            <el-input
              class="ipt"
              v-focus
              v-model.number="form.radius"
              @change="handleRadiusChange"
            />
            <!-- 右侧按钮 -->
            <div class="form-content-right">
              <button
                type="button"
                class="editBtn confirm"
                @click="confirmRadiusChange"
              >
                <i class="icon icon-gou"></i>
              </button>
              <button
                type="button"
                class="editBtn cancel"
                @click="cancelRadiusEdit"
              >
                <i class="icon icon-cha"></i>
              </button>
            </div>
          </template>
          <template v-else>
            <p>{{ form.radius }}m</p>
            <button type="button" @click="editRadius">
              <i class="icon icon-edit-2"></i>
            </button>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="高度：" v-if="!detail.clampToGround">
        <p v-if="state === 'check'">{{ detail.height }}</p>
        <div v-else class="form-content">
          <template v-if="heightEditState">
            <!-- 左侧输入框 -->
            <el-input
              class="ipt"
              v-focus
              v-model.number="form.height"
              @change="handleHeightChange"
            />
            <!-- 右侧按钮 -->
            <div class="form-content-right">
              <button
                type="button"
                class="editBtn confirm"
                @click="confirmHeightChange"
              >
                <i class="icon icon-gou"></i>
              </button>
              <button
                type="button"
                class="editBtn cancel"
                @click="cancelHeightEdit"
              >
                <i class="icon icon-cha"></i>
              </button>
            </div>
          </template>
          <template v-else>
            <p>{{ form.height }}m</p>
            <button type="button" @click="editHeight">
              <i class="icon icon-edit-2"></i>
            </button>
          </template>
        </div>
      </el-form-item>
      <el-form-item>
        <template #label>
          <!-- label -->
          <div class="form-label">
            <el-tooltip v-if="state !== 'check'" placement="bottom-start">
              <div slot="content">
                样式默认来源于数据集设置<br />编辑样式不改变数据集设置
              </div>
              <i class="icon icon-tip"></i>
            </el-tooltip>
            <span>样式：</span>
          </div>
        </template>
        <!-- 图片 -->
        <div class="form-content">
          <div class="img-wrap">
            <img :src="form.style" alt="" />
          </div>
          <button
            v-if="state !== 'check'"
            type="button"
            @click="$store.commit('plot/setIconLibraryDialogVisible', true)"
          >
            <i class="icon icon-edit-2"></i>
          </button>
        </div>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item label-width="0">
        <div class="form-content">
          <div class="form-content-left">
            <template v-if="state === 'check'">
              <!-- 编辑按钮 -->
              <button type="button" @click="edit">
                <i class="icon icon-edit-28"></i>
              </button>
              <!-- 样式按钮 -->
              <button
                type="button"
                @click="$store.commit('plot/setIconLibraryDialogVisible', true)"
              >
                <i class="icon icon-style-28"></i>
              </button>
              <!-- 删除按钮 -->
              <button type="button" @click="$emit('del')">
                <i class="icon icon-del-28"></i>
              </button>
            </template>
          </div>
          <div class="form-btn-group">
            <Button class="form-btn cancel" @click="cancel">取消</Button>
            <Button
              v-if="state !== 'check'"
              class="form-btn confirm"
              @click="confirm('ruleForm')"
              >确定</Button
            >
          </div>
        </div>
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
import Dialog from 'c/basic/Dialog';
import Button from 'c/basic/Button';
import DataclassSelect from 'c/workbench/DataclassSelect';
import { strVerify } from 'p/formVerify';
export default {
  name: 'PlotCircleDialog',
  components: {
    Dialog,
    Button,
    DataclassSelect
  },
  props: {
    // 弹窗状态 add —— 新增  edit —— 编辑 check —— 查看
    state: String,
    // 弹窗查看时详情
    detail: [String, Object],
    coordinates: [String, Array]
  },
  data() {
    // 校验数据集、数据类
    const validateDataClass = (rule, value, callback) => {
      if (!value[0]) {
        callback(new Error('请选择数据类'));
      } else if (!value[1]) {
        callback(new Error('请选择数据集'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: '',
        dataclassId: [],
        clampToGround: this.detail.clampToGround,
        coord: this.coordinates[0] + ',' + this.coordinates[1],
        height: this.coordinates[2],
        radius: this.detail.radius,
        area: this.detail.area,
        style: this.detail.style
      },
      coordEditState: false,
      radiusEditState: false,
      heightEditState: false,
      rules: {
        name: strVerify({ msg: '请输入名称', len: 30 }),
        dataclassId: [
          { required: true, message: '请选择数据集数据类', trigger: 'blur' },
          { validator: validateDataClass, trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    title() {
      let title = '';
      switch (this.state) {
        case 'add':
          title = '新增圆信息';
          break;
        case 'edit':
          title = '修改圆信息';
          break;
        case 'check':
          title = '圆信息';
          break;
      }
      return title;
    }
  },
  watch: {
    // 监听坐标
    coordinates(coordinates) {
      if (coordinates) {
        this.form.coord = coordinates[0] + ',' + coordinates[1];
        this.form.height = coordinates[2];
      } else {
        this.form.coord = '';
        this.form.height = '';
      }
    }
  },
  methods: {
    // 数据类编辑
    handleDataclassSelectChange() {},
    // 进入编辑状态
    edit() {
      const state = 'edit';
      this.$emit('stateChange', state);
      // 从详情获取数据
      const {
        name,
        datasetName,
        dataclassName,
        clampToGround,
        height,
        area,
        style
      } = this.detail;
      let dataclassId = [datasetName, dataclassName];
      this.form = {
        name,
        dataclassId,
        clampToGround,
        height,
        area,
        style
      };
    },
    confirmRadiusChange() {
      this.radiusEditState = false;
    },
    handleRadiusChange() {
      this.$emit('radiusChange', this.form.radius);
    },
    cancelRadiusEdit() {
      // 退出编辑
      this.radiusEditState = false;
      // 还原高度
      this.radius = this.originRadius;
      this.handleRadiusChange();
    },
    editRadius() {
      this.radiusEditState = true;
      this.originRadius = this.form.radius;
    },
    confirmHeightChange() {
      this.heightEditState = false;
    },
    confirmCoordChange() {
      this.coordEditState = false;
    },
    // 更新坐标
    handleCoordChange() {
      const coord = this.form.coord.split(',').map(item => parseFloat(item));
      this.$emit('coordChange', [...coord, this.form.height]);
    },
    // 取消坐标编辑
    cancelCoordEdit() {
      // 退出编辑
      this.coordEditState = false;
      // 还原坐标
      this.form.coord = this.originCoord;
      this.handleCoordChange();
    },
    // 编辑坐标
    editCoord() {
      this.coordEditState = true;
      this.originCoord = this.form.coord;
    },
    // 取消高度编辑
    cancelHeightEdit() {
      // 退出编辑
      this.heightEditState = false;
      // 还原高度
      this.height = this.originHeight;
      this.handleCoordChange();
    },
    // 编辑高度
    editHeight() {
      this.heightEditState = true;
      this.originHeight = this.height;
    },
    // 确定
    confirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { name, dataclassId, area, height, style, clampToGround } =
            this.form;
          // 点位信息
          const info = {
            name,
            area,
            clampToGround,
            height,
            datasetName: dataclassId[0],
            dataclassName: dataclassId[1],
            style
          };
          this.$emit('confirm', info);
          this.$emit('close');
        }
      });
    },
    // 取消
    cancel() {
      if (this.state === 'add') {
        this.$emit('clear');
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.form-btn-group {
  margin-top: 0 !important;
}

.form {
  // 表单label
  &-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      margin-right: 8px;
    }
  }
  // 表单content
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    &-left {
      display: flex;
      align-items: center;

      button {
        margin-right: 30px;
      }
    }

    &-right {
      display: flex;
    }

    .ipt {
      width: 150px;
    }

    .img-wrap {
      width: 28px;
      height: 28px;
      background: #eaeefe;
      border-radius: 8px;
    }

    // 编辑按钮
    .editBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      &.confirm {
        background: #507cff;
        margin-right: 14px;
      }
      &.cancel {
        background: #d6e0ff;
      }
    }
  }
}
</style>
