<template>
  <el-dialog
    :title="title"
    width="27.39583vw"
    :visible.sync="plotAddDataclassDialogVisible"
    :modal-append-to-body="false"
  >
    <el-form
      label-position="left"
      label-width="6.25vw"
      ref="form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="数据集：" v-if="!datasetId" prop="datasetName">
        <el-input
          v-model.trim="form.datasetName"
          placeholder="请输入数据集名称"
          maxlength="20"
        />
      </el-form-item>
      <el-form-item label="数据类：" prop="dataclassName">
        <el-input
          v-model.trim="form.dataclassName"
          placeholder="请输入数据类名称"
          maxlength="20"
        />
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="plotAddDataclassDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="handleConfirm('form')"
            >确定</Button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import $bus from 'p/utils';
import Button from 'c/Button';
import { strVerify } from 'p/formVerify';
export default {
  name: 'PlotAddDataclassDialog',
  props: {
    title: String,
    // 数据集id
    datasetId: [Number, String],
    // 数据集类型
    dataType: Number,
    // 数据类类型
    dataclassType: Number
  },
  components: {
    Button
  },
  data() {
    return {
      form: {
        datasetName: '',
        dataclassName: ''
      },
      rules: {
        datasetName: strVerify({ msg: '请输入数据集名称', len: 20 }),
        dataclassName: strVerify({ msg: '请输入数据类名称', len: 20 })
      }
    };
  },
  computed: {
    plotAddDataclassDialogVisible: {
      get() {
        return this.$store.state.plot.plotAddDataclassDialogVisible;
      },
      set(val) {
        this.$store.commit('plot/setPlotAddDataclassDialogVisible', val);
      }
    },
    workspaceId() {
      return this.$store.state.common.workspaceId;
    }
  },
  methods: {
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { datasetId, dataclassType, form } = this;
          const { datasetName, dataclassName } = form;
          // 判断添加数据集还是数据类
          if (datasetId) {
            // 添加数据类
            const params = {
              datasetId,
              dataType: dataclassType,
              name: dataclassName
            };
            this.$service.data.featureClassAdd(params).then(res => {
              if (res.data.status === 200) {
                $bus.$emit('refreshDS');
                this.$emit('confirm', { dataclassId: res.data.data.id });
              }
            });
          } else {
            // 添加数据集
            this.$service.data
              .datasetAdd({
                name: datasetName,
                type: 1,
                workspaceId: this.workspaceId
              })
              .then(res => {
                if (res.data.status === 200) {
                  const datasetId = res.data.data.id;
                  // 添加数据类
                  const params = {
                    datasetId,
                    dataType: dataclassType,
                    name: dataclassName
                  };
                  this.$service.data.featureClassAdd(params).then(res => {
                    if (res.data.status === 200) {
                      this.$emit('confirm', {
                        datasetId,
                        dataclassId: res.data.data.id
                      });
                      $bus.$emit('refreshDS');
                    } else {
                      // 删除之前创建的数据集
                      this.$service.data.datasetDel({ id: datasetId });
                    }
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
