<template>
  <!-- 控制器 -->
  <div class="control">
    <!-- 指南针 -->
    <button
      v-if="mapCompassVisible"
      class="control-btnCompass"
      @click="mapControlClick(7)"
    >
      <i
        class="icon-control-compass"
        :style="`transform: rotate(${360 - rotate}deg)`"
      ></i>
    </button>
    <!-- 3D -->
    <!-- <button
      v-if="mapModelSwitchVisible"
      class="control-btn3D"
      @click="mapControlClick(8)"
    >
      3D
    </button> -->
    <!-- 放大 -->
    <button
      v-if="mapZoomVisible"
      class="control-btnPlus"
      @click="mapControlClick(1)"
    >
      <i class="icon-control-plus"></i>
    </button>
    <!-- 缩小 -->
    <button
      v-if="mapZoomVisible"
      class="control-btnMinus"
      @click="mapControlClick(2)"
    >
      <i class="icon-control-minus"></i>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { longmapPoint } from 'c/longmap/Tool';
export default {
  name: 'MapControl',
  inject: ['getMap'],
  props: {
    compassAngle: Number
  },
  computed: {
    rotate() {
      return this.compassAngle;
    },
    ...mapState('baseMap', [
      'mapCompassVisible',
      'mapModelSwitchVisible',
      'mapZoomVisible'
    ])
  },
  methods: {
    mapControlClick(type) {
      let center = window.defaultCenter;
      this.getMap().then(map => {
        switch (type) {
          case 1:
            map.controls.zoomOut();
            break;
          case 2:
            map.controls.zoomIn();
            break;
          case 3:
            map.controls.flipUp(5, 0.5);
            break;
          case 4:
            map.controls.flipUp(5, 0.5);
            break;
          case 5:
            map.controls.rotateLeft(5, 0.5);
            break;
          case 6:
            map.controls.rotateRight(5, 0.5);
            break;
          case 7:
            map.flyTo({
              destination: longmapPoint(center),
              height: center[2],
              time: 1,
              rotateAngle: 0
            });
            break;
          case 8:
            break;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.control {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  right: 20px;
  width: 38px;
  margin: auto;
  &-btnCompass {
    .icon-control-compass {
      background-size: contain;
      position: absolute;
      margin: -50px 0 0 -28px;
      width: 50px;
      height: 50px;
      background-image: url(~a/map/icon-measure-compass.png);
    }
  }
  &-btn3D {
    margin-top: 20px;
    width: 38px;
    height: 38px;
    background: rgba(171, 186, 254, 0.6);
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    &:hover {
      color: #fea313;
    }
  }
  &-btnPlus {
    margin-top: 10px;
    width: 38px;
    height: 38px;
    background: rgba(171, 186, 254, 0.6);
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #abbafe;
    .icon-control-plus {
      background-size: contain;
      position: absolute;
      margin: -10px;
      width: 20px;
      height: 20px;
      background-image: url(~a/map/icon-measure-plus.png);
    }
    &:hover {
      .icon-control-plus {
        background-image: url(~a/map/icon-measure-plus-a.png);
      }
    }
  }
  &-btnMinus {
    width: 38px;
    height: 38px;
    background: rgba(171, 186, 254, 0.6);
    border-radius: 0 0 4px 4px;
    .icon-control-minus {
      background-size: contain;
      position: absolute;
      margin: -10px;
      width: 20px;
      height: 20px;
      background-image: url(~a/map/icon-measure-minus.png);
    }
    &:hover {
      .icon-control-minus {
        background-image: url(~a/map/icon-measure-minus-a.png);
      }
    }
  }
}
.icon {
  display: flex;
  .icon-control-dot {
    width: 16px;
    height: 16px;
    background-image: url(~a/map/icon-measure-dot.png);
  }
}
</style>
