// LongMap工具方法

/**
 * 坐标转换
 * @param {Array, Object} point [ [lon,lat] 一维或二维数组，Point对象，[Point对象]]
 */
function longmapPoint(points) {
  if (!points) {
    console.error('point参数points undefined');
    return;
  }
  // 判断是否为Point对象
  if (
    points.__proto__.type === 'Point' ||
    points[0].__proto__.type === 'Point'
  ) {
    return points;
  }
  // 判断是一维还是二维数组
  const isDeep = points.some(item => item instanceof Array);
  if (isDeep) {
    // 二维数组
    return points.map(
      point => new window.LongMap.Point(point[0], point[1], point[2])
    );
  } else {
    return new window.LongMap.Point(points[0], points[1], points[2]);
  }
}
/**
 * 转化颜色
 * @param {Array} color [ [hex,opacity] ]
 */
function longmapColor(color) {
  if (!color) {
    console.error('color undefined');
    return;
  }
  return new window.LongMap.Color(color[0], color[1]);
}

// 计算长度
const getLength = points => {
  if (points[0] instanceof Array) {
    return window.LongMap.Tool.getLength(longmapPoint(points));
  } else {
    return window.LongMap.Tool.getLength(points);
  }
};

// 计算面积
const getArea = points => {
  if (points[0] instanceof Array) {
    return window.LongMap.Tool.getArea(longmapPoint(points));
  } else {
    return window.LongMap.Tool.getArea(points);
  }
};

export { longmapPoint, longmapColor, getLength, getArea };
