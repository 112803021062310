<template>
  <!-- 弹窗 -->
  <div>
    <div class="dialog" :style="{ width, ...position }">
      <div class="dialog-header">
        <slot name="header">
          <h1 class="dialog-header-title">{{ title }}</h1>
        </slot>
        <i class="iconfont icon-close" @click="$emit('close')"></i>
      </div>
      <div class="dialog-content">
        <slot></slot>
      </div>
      <div class="dialog-footer">
        <slot name="footer"></slot>
      </div>
    </div>
    <!-- 阴影遮罩 -->
    <div v-if="shadow" class="dialog-shadow"></div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: String,
    width: String,
    position: {
      type: Object,
      default: () => {}
    },
    shadow: Boolean
  }
};
</script>

<style lang="less" scoped>
.dialog {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  // 头部
  &-header {
    position: relative;
    padding: 20px 30px 10px;
    border-bottom: 1px solid #d2d2d2;
    // 标题
    &-title {
      color: #333;
      font-size: 20px;
      font-weight: bold;
    }
    // 关闭按钮
    .icon-close {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: #333;
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  // 主体
  &-content {
    padding: 20px 40px;
    max-height: 50vh;
    overflow-y: auto;
  }
  // 阴影遮罩
  &-shadow {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
