<template>
  <!-- 图层管理地图数据 -->
  <LongMapLayer>
    <template v-for="layerData in layerManagerData">
      <!-- 按图层分类 -->
      <template v-for="(featuresData, type) in layerData">
        <template v-if="type === 'Point'">
          <LongMapMark
            v-for="feature in featuresData"
            :key="
              feature.featureData.dialogData.layerId +
              '_' +
              feature.featureData.dialogData.id
            "
            v-bind="feature"
          />
        </template>
        <template v-if="type === 'Polyline'">
          <LongMapPolyline
            v-for="feature in featuresData"
            :key="
              feature.featureData.dialogData.layerId +
              '_' +
              feature.featureData.dialogData.id
            "
            v-bind="feature"
          />
        </template>
        <template v-if="type === 'Polygon'">
          <LongMapPolygon
            v-for="feature in featuresData"
            :key="
              feature.featureData.dialogData.layerId +
              '_' +
              feature.featureData.dialogData.id
            "
            v-bind="feature"
          />
        </template>
      </template>
    </template>
  </LongMapLayer>
</template>

<script>
import LongMapLayer from 'c/longmap/LongMapLayer';
import LongMapMark from 'c/longmap/LongMapMark';
import LongMapPolyline from 'c/longmap/LongMapPolyline';
import LongMapPolygon from 'c/longmap/LongMapPolygon';
import { mapState } from 'vuex';
export default {
  name: 'MapLayerManager',
  components: {
    LongMapPolygon,
    LongMapPolyline,
    LongMapMark,
    LongMapLayer
  },
  computed: {
    ...mapState('layerData', ['layerManagerData'])
  }
};
</script>
