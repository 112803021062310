<template>
  <div class="longmap-polyline">
    <slot></slot>
  </div>
</template>

<script>
import { longmapPoint, longmapColor } from './Tool';
export default {
  name: 'LongMapPolyline',
  inject: ['getMap', 'getLayer'],
  props: {
    // 线段类型，初始化参数相关
    type: {
      type: String,
      default: 'segmentLine',
      validator: function (value) {
        // 多段线、边框线、发光线
        return ['segmentLine', 'outLine', 'glowLine'].includes(value);
      }
    },
    coordinates: {
      type: Array,
      required: true
    },
    color: {
      type: Array,
      default: () => ['#00A2E8', 1]
    },
    segmentColors: {
      type: Array,
      default: () => [['#00A2E8', 1]]
    },
    // 线宽
    linewidth: {
      type: Number,
      default: 3
    },
    outlineColor: {
      type: Array,
      default: () => ['#FFFFFF', 1]
    },
    // 线宽
    outlineWidth: {
      type: Number,
      default: 2
    },
    edit: Boolean,
    show: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    change: Function, // 编辑回调事件
    featureData: Object // 自定义数据
  },
  mounted() {
    this.init();
  },
  // 组件销毁时，移除元素
  destroyed() {
    this.remove();
  },
  methods: {
    init() {
      const {
        type,
        coordinates,
        linewidth,
        color,
        segmentColors,
        outlineColor,
        outlinewidth,
        featureData,
        edit,
        show,
        zIndex
      } = this;
      this.getLayer().then(layer => {
        let material = '';
        switch (type) {
          case 'segmentLine':
            {
              material = new window.THREE.LineSegmentMaterial({
                transparent: true,
                segmentSize: 200,
                segmentColors: segmentColors.map(color => longmapColor(color)),
                linewidth
              });
            }
            break;
          case 'outLine':
            material = new window.THREE.LineOutlineMaterial({
              transparent: true,
              dashed: true,
              color: longmapColor(color),
              outlinecolor: longmapColor(outlineColor),
              linewidth,
              outlinewidth
            });
            break;
          case 'glowLine':
            material = new window.THREE.LineGlowMaterial({
              transparent: true,
              dashed: true,
              color: longmapColor(color)
            });
            break;
          default:
            material = longmapColor(color);
            break;
        }
        let feature = new window.LongMap.Polyline({
          points: longmapPoint(coordinates),
          zIndex,
          material
        });
        feature.featureData = featureData;
        this.feature = feature;
        layer.add(feature);

        // 是否开启编辑
        if (edit) {
          this.onEdit();
        }
        // 是否隐藏
        if (!show) {
          feature.show = false;
        }
      });
    },
    remove() {
      if (this.feature) {
        if (this.edit) {
          this.offEdit();
        }
        this.feature.remove();
      }
    },
    onEdit() {
      const { feature, change } = this;
      feature.edit = true;
      feature.change = change;
    },
    offEdit() {
      const { feature } = this;
      feature.edit = false;
      feature.change = undefined;
    }
  },
  watch: {
    coordinates() {
      this.remove();
      this.init();
    },
    color(color) {
      const type = this.type;
      if (type === 'segmentLine') {
        let segmentColors = [longmapColor(color)];
        this.feature.setMaterial(
          new window.THREE.LineSegmentMaterial({
            transparent: true,
            segmentSize: 200,
            segmentColors,
            linewidth: this.linewidth
          })
        );
      }
    },
    segmentColors(segmentColors) {
      segmentColors = segmentColors.map(color => longmapColor(color));
      this.feature.setMaterial(
        new window.THREE.LineSegmentMaterial({
          transparent: true,
          segmentSize: 200,
          segmentColors,
          linewidth: this.linewidth
        })
      );
    },
    edit(edit) {
      if (edit) {
        this.onEdit();
      } else {
        this.offEdit();
      }
    },
    show(show) {
      let feature = this.feature;
      if (show) {
        feature.show = true;
      } else {
        feature.show = false;
      }
    }
  }
};
</script>
