<template>
  <!-- 数据类级联下拉框 -->
  <div class="select-wrap">
    <el-select
      class="select"
      v-model="val[0]"
      ref="datasetSelect"
      @change="handleDatasetSelectChange"
    >
      <el-option
        v-for="option in datasetOptions"
        :key="'dataset_' + option.id"
        :label="option.name"
        :value="option.id"
      />
      <el-button type="button" class="createDataBtn" @click="createDataset"
        >新建数据集</el-button
      >
      <template #empty>
        <el-button type="button" class="createDataBtn" @click="createDataset"
          >新建数据集</el-button
        >
      </template>
    </el-select>
    <el-select
      class="select"
      v-model="val[1]"
      ref="dataclassSelect"
      @change="handleDataclassSelectChange"
    >
      <el-option
        v-for="option in dataclassOptions"
        :key="'dataclass_' + option.id"
        :label="option.name"
        :value="option.id"
      />
      <el-button type="button" class="createDataBtn" @click="createDataclass"
        >新建数据类</el-button
      >
      <template #empty>
        <el-button type="button" class="createDataBtn" @click="createDataclass"
          >新建数据类</el-button
        >
      </template>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'DataclassSelect',
  model: {
    event: 'change'
  },
  props: {
    // 数据集类型
    dataType: Number,
    // 数据类类型
    dataclassType: Number,
    value: Array
  },
  data() {
    return {
      val: this.value,
      datasetName: '',
      dataclassName: '',
      datasetOptions: [],
      dataclassOptions: []
    };
  },
  computed: {
    workspaceId() {
      return this.$store.state.common.workspaceId;
    }
  },
  created() {
    this.getDataset();
    // 获取数据类
    if (this.value[1]) {
      this.getDataclass();
    }
  },
  watch: {
    value(value) {
      // 判断数据集、数据类是否改变
      if (value[0] !== this.val[0]) {
        // 更新数据集
        this.getDataset();
        this.val[0] = value[0];
      }
      if (value[1] !== this.val[1]) {
        this.getDataclass();
        this.val[1] = value[1];
      }
    }
  },
  methods: {
    // 新建数据集
    createDataset() {
      const { dataType, dataclassType } = this;
      this.$emit('createDataset', { dataType, dataclassType });
      this.$refs.datasetSelect.blur();
    },
    // 新建数据类
    createDataclass() {
      const { dataType, dataclassType, val } = this;
      this.$emit('createDataclass', {
        datasetId: val[0],
        dataType,
        dataclassType
      });
      this.$refs.dataclassSelect.blur();
    },
    // 选择数据类
    handleDataclassSelectChange() {
      this.$nextTick(() => {
        const dataclassSelectRef = this.$refs.dataclassSelect;
        this.dataclassName = dataclassSelectRef.selectedLabel;
        this.$emit('change', this.val);
      });
    },
    // 选择数据集
    handleDatasetSelectChange() {
      this.val[1] = '';
      this.getDataclass();
      this.$nextTick(() => {
        const datasetSelectRef = this.$refs.datasetSelect;
        this.datasetName = datasetSelectRef.selectedLabel;
      });
    },
    getDataset() {
      const { dataType: type, workspaceId } = this;
      // 获取数据集
      this.$service.data.datasetGetAll({ type, workspaceId }).then(res => {
        const data = res.data;
        if (data.status === 200) {
          if (data.length === 0) {
            this.$emit('empty');
          } else {
            this.datasetOptions = data.data;
            // 更新名称
            if (this.val[0]) {
              this.$nextTick(() => {
                this.datasetName = this.$refs.datasetSelect.selectedLabel;
              });
            }
          }
        }
      });
    },
    getDataclass() {
      let req = '';
      switch (this.dataType) {
        // 要素
        case 1:
          req = 'featureClassGetByDataset';
          break;
      }
      // 获取数据类
      this.$service.data[req]({
        datasetId: this.val[0],
        type: this.dataclassType
      }).then(res => {
        const data = res.data;
        if (data.status === 200) {
          if (data.length === 0) {
            this.$emit('empty');
          } else {
            this.dataclassOptions = data.data;
            // 更新名称
            if (this.val[1]) {
              this.$nextTick(() => {
                this.dataclassName = this.$refs.dataclassSelect.selectedLabel;
              });
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.select {
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
}
</style>
