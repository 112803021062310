<template>
  <div
    class="mapPage"
    :class="fullScreen ? 'fullScreen' : ''"
    @click.capture="bodyClick"
  >
    <!-- 地图相关组件 -->
    <LongMap
      ref="map"
      id="longmap"
      class="longmap"
      :center="center"
      :zoom="zoom"
      :rotate="rotate"
      :pan="pan"
      :enabled="enabled"
      @click="handleMapClick"
      @change="handleMapChange"
    >
      <!-- <LongMap3DTiles
        :config="{
          url: '/mapIp/map/changsha/resources/model/model/tileset.json',
          maximumScreenSpaceError: 32,
          position: [113.76013238627816, 22.715531199468984, 100]
        }"
      /> -->
      <!-- 底图 -->
      <LongMapUrlTemplateImageryProvider
        v-if="providerData"
        v-bind="providerData"
      />
      <!-- 全屏按钮 -->
      <MapFullScreenBtn class="mapFullScreenBtn" />
      <!-- 标注 -->
      <MapPlot class="mapPlot" v-if="mapDrawVisible" />
      <!-- 测量 -->
      <MapMesaure
        ref="mapMeasure"
        class="mapMeasure"
        v-if="mapMeasureVisible"
      />
      <!-- 地图控制 -->
      <MapControl :compassAngle="compassAngle" />
      <!-- 底部地图基本信息 -->
      <MapInfo
        ref="mapControl"
        :map-info="mapInfo"
        :style="{ left: showAllMap ? '0' : '17.1875vw' }"
      />
      <MapDataManager />
      <MapLayerManager />
    </LongMap>
    <!-- 右键菜单 -->
    <ContextMenu
      v-show="contextMenuVisible"
      :data="contextMenuData"
      :position="contextMenuPosition"
      :style="{
        position: 'fixed'
      }"
    />
    <router-view class="mapPage-view" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LongMap from 'c/longmap/LongMap';
// import LongMap3DTiles from 'c/longmap/LongMap3DTiles';
import LongMapUrlTemplateImageryProvider from 'c/longmap/LongMapUrlTemplateImageryProvider';
import ContextMenu from 'c/basic/ContextMenu';
import MapPlot from './map/MapPlot.vue';
import MapFullScreenBtn from './map/MapFullScreenBtn.vue';
import MapMesaure from './map/MapMesaure.vue';
import MapInfo from './map/MapInfo.vue';
import MapControl from './map/MapControl.vue';
import MapDataManager from './map/MapDataManager.vue';
import MapLayerManager from './map/MapLayerManager.vue';
export default {
  name: 'MapPage',
  components: {
    MapLayerManager,
    MapDataManager,
    MapFullScreenBtn,
    MapMesaure,
    MapPlot,
    MapControl,
    MapInfo,
    LongMapUrlTemplateImageryProvider,
    // LongMap3DTiles,
    LongMap,
    ContextMenu
  },
  data() {
    return {
      // 地图信息
      mapInfo: {
        longitude: 0, // 经度
        latitude: 0, // 纬度
        heading: 0, // 旋转角度
        pitch: 0, // 俯视角度
        height: 0, // 高度
        mapScaleRuler: 0 // 比例尺
      },
      compassAngle: 0, //指北针角度
      showAllMap: false // 展示全部地图
    };
  },
  computed: {
    ...mapState('baseMap', [
      'center',
      'zoom',
      'rotate',
      'pan',
      'enabled',
      'providerData',
      'fullScreen',
      'mapDrawVisible',
      'mapMeasureVisible'
    ]),
    ...mapState('common', [
      'contextMenuVisible',
      'contextMenuData',
      'contextMenuPosition'
    ])
  },
  created() {
    // 获取地图设置相关配置
  },
  methods: {
    bodyClick() {
      // 右键菜单隐藏时
      if (!this.contextMenuVisible) {
        // 退出图层编辑和选中状态
        // this.$store.commit('setEditLayerId', '');
        // this.$store.commit('setSelectedLayerId', '');
        // 退出数据编辑和选中状态
        // this.$store.commit('setEditDataId', '');
        // this.$store.commit('setSelectedDataId', '');
      }
      // 关闭右键弹窗
      this.$store.commit('common/setContextMenuVisible', false);
    },
    handleMapClick(e) {
      console.log('mapClick:', e);
    },
    handleMapChange(e) {
      // 地图信息
      let { longitude, latitude, height, rotatAngle, flipAngle } = e;
      this.mapInfo.longitude = longitude.toFixed(6);
      this.mapInfo.latitude = latitude.toFixed(6);
      this.mapInfo.height = parseInt(height) + '米';
      this.mapInfo.heading = parseInt(this.r2Angle(rotatAngle)) + '°';
      this.mapInfo.pitch = parseInt(this.r2Angle(flipAngle)) + '°';
      // this.mapInfo.mapScaleRuler = this.$refs.map.map.scaleRuler();
      //指北针角度
      this.compassAngle = parseInt(this.r2Angle(rotatAngle));
    },
    r2Angle(r) {
      return (r * 180) / Math.PI;
    }
  },
  beforeRouteEnter(to, from, next) {
    const path = to.path;
    next(vm => {
      vm.showAllMap = path === '/workbench/map';
    });
  },
  beforeRouteUpdate(to, from, next) {
    const path = to.path;
    this.showAllMap = path === '/workbench/map';
    next();
  }
};
</script>

<style lang="less" scoped>
.mapPage {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
}
.longmap {
  width: 100%;
  height: 100%;
}
// 地图全屏样式
.fullScreen {
  position: fixed;
  // 确保在最上层
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
// 全屏按钮
.mapFullScreenBtn {
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>

<style lang="less" scoped>
// element自定义样式
.mapPage,
.mapPage-view {
  /deep/ .el {
    // 表单
    &-form {
      &-item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &__label {
          color: #333;
          font-size: 18px;
          text-align: right;
        }

        &__content {
          .el-input,
          .el-textarea {
            &__inner {
              color: #333;
              font-family: '微软雅黑';
              font-size: 16px;
              &::placeholder {
                color: #999;
              }
            }
          }
        }
      }
    }
  }

  /deep/ .form-btn {
    width: 100px;
    height: 36px;
    background: #d6e0ff;
    border-radius: 6px;
    font-size: 16px;
    &.cancel {
      background: #d6e0ff;
      color: #507cff;
    }
    &.confirm {
      margin-left: 20px;
      background: #507cff;
      color: #fff;
    }
    &-group {
      margin-top: 28px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
