<template>
  <div class="longmap-circle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LongMapCircle',
  inject: ['getLayer', 'longmapPoint', 'longmapColor'],
  props: {
    coordinates: {
      type: Array,
      required: true
    },
    radius: Number,
    fillColor: {
      type: Array,
      default: () => ['#00A2E8', 0.7]
    },
    borderColor: {
      type: Array,
      default: () => ['#00A2E8']
    },
    borderWidth: {
      type: Number,
      default: 3
    },
    // 是否贴地
    clampToGround: {
      type: Boolean,
      default: true
    },
    // 底面高度
    height: {
      type: Number,
      default: 0
    },
    // 顶面高度 （体高度为extrudedHeight-height）
    extrudedHeight: {
      type: Number,
      default: 0
    },
    edit: Boolean, // 编辑
    drag: Boolean, // 拖动
    show: {
      type: Boolean,
      default: true
    },
    featureData: Object // 自定义数据
  },
  mounted() {
    this.init();
  },
  // 组件销毁时，移除元素
  destroyed() {
    this.remove();
  },
  methods: {
    init() {
      this.getLayer().then(layer => {
        const {
          coordinates,
          radius,
          fillColor,
          borderColor,
          borderWidth,
          height,
          extrudedHeight,
          clampToGround,
          edit,
          drag,
          show,
          featureData,
          longmapPoint,
          longmapColor
        } = this;
        let feature = new window.LongMap.Circle({
          position: longmapPoint(coordinates),
          radius,
          fillColor: longmapColor(fillColor),
          borderColor: longmapColor(borderColor),
          borderWidth,
          height,
          extrudedHeight,
          clampToGround
        });
        feature.featureData = featureData;
        layer.addFeature(feature);
        this.feature = feature;
        // 是否开启编辑
        if (edit) {
          feature.enableEditing();
        }
        // 是否可拖动
        if (drag) {
          feature.enableDrag();
        }
        // 是否隐藏
        if (!show) {
          feature.hide();
        }
      });
    },
    remove() {
      if (this.feature) {
        this.feature.disableDrag();
        this.feature.disableEditing();
        this.feature.remove();
      }
    },
    getFeature() {
      return new Promise(resolve => {
        let feature = this.feature;
        if (feature) {
          resolve(feature);
        } else {
          // 定时去获取feature,获取到feature后清除定时器
          let timer = setInterval(() => {
            let feature = this.feature;
            if (feature) {
              resolve(feature);
              clearInterval(timer);
            }
          }, 500);
        }
      });
    }
  },
  watch: {
    drag(drag) {
      if (drag) {
        this.feature.enableDrag();
      } else {
        this.feature.disableDrag();
      }
    },
    edit(edit) {
      if (edit) {
        this.feature.enableEditing();
      } else {
        this.feature.disableEditing();
      }
    },
    coordinates(coordinates) {
      this.feature.setCoordinates(coordinates);
    },
    height(height) {
      this.feature.setHeight(height);
    },
    borderWidth(borderWidth) {
      this.feature.setBorderWidth(borderWidth);
    },
    extrudedHeight(extrudedHeight) {
      this.feature.setExtrudedHeight(extrudedHeight);
    },
    fillColor(fillColor) {
      this.feature.setFillColor(this.longmapColor(fillColor));
    },
    borderColor(borderColor) {
      this.feature.setBorderColor(this.longmapColor(borderColor));
    },
    radius(radius) {
      this.feature.setRadius(radius);
    },
    show(show) {
      let feature = this.feature;
      if (show) {
        feature.show();
      } else {
        feature.hide();
      }
    }
  }
};
</script>
