<template>
  <Dialog
    :title="title"
    width="22.39583vw"
    :position="{
      top: '9.89583vw',
      right: '11.19792vw',
      left: 'initial',
      transform: 'initial'
    }"
    @close="cancel"
  >
    <el-form
      class="form"
      label-position="left"
      label-width="5.5vw"
      :model="form"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="名称：" prop="name">
        <p v-if="state === 'check'">{{ detail.name }}</p>
        <el-input v-else v-model="form.name" />
      </el-form-item>
      <el-form-item label="位置：" prop="dataclassId">
        <p v-if="state === 'check'">
          {{ detail.datasetName }}——{{ detail.dataclassName }}
        </p>
        <DataclassSelect
          v-else
          ref="dataclassSelect"
          :dataType="1"
          :dataclassType="5"
          v-model="form.dataclassId"
          @change="handleDataclassSelectChange"
          @createDataset="data => $emit('createDataset', data)"
          @createDataclass="data => $emit('createDataclass', data)"
        />
      </el-form-item>
      <!-- 自定义字段 -->
      <el-form-item
        class="customField-form-item"
        v-for="field in customFieldsArr"
        :key="field.id"
        :label="field.name"
        :prop="`customFields.${field.id}`"
      >
        <template #label>
          <div class="form-label">
            <el-tooltip placement="bottom-start">
              <div slot="content">
                {{ field.name }}
              </div>
              <p class="form-label-content">{{ field.name }}</p>
            </el-tooltip>
            ：
          </div>
        </template>
        <template v-if="state === 'check'">
          <p>
            {{
              detail.customFieldJson
                ? JSON.parse(detail.customFieldJson)[field.id]
                : ''
            }}
          </p>
        </template>
        <template v-else>
          <template v-if="field.fieldType !== 3">
            <el-input v-model="form.customFields[field.id]" />
          </template>
          <template v-else>
            <el-date-picker
              class="datePicker"
              v-model="form.customFields[field.id]"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </template>
      </el-form-item>
      <el-form-item v-if="!detail.layerId">
        <!-- 样式 -->
        <template #label>
          <!-- label -->
          <div class="form-label">
            <el-tooltip v-if="state !== 'check'" placement="bottom-start">
              <div slot="content">
                样式默认来源于数据集设置<br />编辑样式不改变数据集设置
              </div>
              <i class="icon icon-tip"></i>
            </el-tooltip>
            <span>样式：</span>
          </div>
        </template>
        <!-- 多边形 -->
        <div class="form-content style-content">
          <div class="img-wrap">
            <div class="style-polygon-box" v-if="fillColor">
              <div class="style-polygon-box-content">
                <div class="style-polygon-box-content-top">
                  <div
                    class="item-width"
                    v-for="(color, index) in frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
                <div class="style-polygon-box-content-box">
                  <div class="style-polygon-box-content-left">
                    <div
                      v-show="index > 4"
                      class="item-height"
                      v-for="(color, index) in frameFillColor"
                      :key="'1_' + index"
                      :style="{ backgroundColor: color }"
                    ></div>
                  </div>
                  <!-- 填充内容 -->
                  <div
                    class="style-polygon-box-content-color"
                    :style="{ background: fillColor }"
                  ></div>
                  <div class="style-polygon-box-content-right">
                    <div
                      v-show="index < 5"
                      class="item-height"
                      v-for="(color, index) in frameFillColor"
                      :key="'1_' + index"
                      :style="{ backgroundColor: color }"
                    ></div>
                  </div>
                </div>
                <div class="style-polygon-box-content-bottom">
                  <div
                    class="item-width"
                    v-show="index > 4"
                    v-for="(color, index) in frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                  <div
                    class="item-width"
                    v-show="index < 5"
                    v-for="(color, index) in frameFillColor"
                    :key="'2_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="state !== 'check'"
            type="button"
            @click="hanldeStyleEdit"
          >
            <i class="icon icon-edit-2"></i>
          </button>
        </div>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-content">
          <div class="form-content-left">
            <template v-if="state === 'check'">
              <!-- 编辑按钮 -->
              <button type="button" @click="edit">
                <i class="icon icon-edit-28"></i>
              </button>
              <!-- 样式按钮 -->
              <button
                v-if="!detail.layerId"
                type="button"
                @click="hanldeStyleEdit"
              >
                <i class="icon icon-style-28"></i>
              </button>
              <!-- 删除按钮 -->
              <button type="button" @click="$emit('del')">
                <i class="icon icon-del-28"></i>
              </button>
            </template>
          </div>
          <div class="form-btn-group">
            <Button class="form-btn cancel" @click="cancel">取消</Button>
            <Button
              v-if="state !== 'check'"
              class="form-btn confirm"
              @click="confirm('ruleForm')"
              >确定</Button
            >
          </div>
        </div>
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
import $bus from 'p/utils';
import Dialog from 'c/basic/Dialog';
import Button from 'c/basic/Button';
import DataclassSelect from 'c/workbench/DataclassSelect';
import {
  strVerify,
  phoneVerify,
  websiteVerify,
  numberVerify
} from 'p/formVerify';
export default {
  name: 'PlotPolygonDialog',
  components: {
    Dialog,
    Button,
    DataclassSelect
  },
  props: {
    // 弹窗状态 add —— 新增  edit —— 编辑 check —— 查看
    state: String,
    // 弹窗查看时详情
    detail: [String, Object],
    // 线段坐标
    coordinates: Array
  },
  data() {
    // 校验数据集、数据类
    const validateDataClass = (rule, value, callback) => {
      if (!value[0]) {
        callback(new Error('请选择数据集'));
      } else if (!value[1]) {
        callback(new Error('请选择数据类'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: '',
        dataclassId: [],
        styleResourcesId: '',
        customFields: {}
      },
      // 多边形
      fillColor: '',
      frameFillColor: [],
      // 自定义字段
      customFieldsArr: [],
      rules: {
        name: strVerify({ msg: '请输入名称', len: 30 }),
        dataclassId: [
          { required: true, message: '请选择数据集数据类', trigger: 'blur' },
          { validator: validateDataClass, trigger: 'change' }
        ]
      }
    };
  },
  computed: {
    title() {
      let title = '';
      switch (this.state) {
        case 'add':
          title = '新增多边形信息';
          break;
        case 'edit':
          title = '修改多边形信息';
          break;
        case 'check':
          title = '多边形信息';
          break;
      }
      return title;
    }
  },
  mounted() {
    $bus.$off('getStyleData');
    // 更新样式
    $bus.$on('getStyleData', data => {
      const { id: styleResourcesId, fillColor, frameFillColor } = data;
      this.form.styleResourcesId = styleResourcesId;
      this.fillColor = fillColor;
      this.frameFillColor = frameFillColor;
      this.$emit('updatePolygon', fillColor);
      // 查看状态下
      if (this.state === 'check') {
        this.$service.feature
          .polygonStyleSet({ id: this.detail.id, styleResourcesId })
          .then(res => {
            if (res.status === 200) {
              this.$message.success(res.msg);
              this.detail.styleResourcesId = styleResourcesId;
              this.detail.fillColor = fillColor;
              this.detail.frameFillColor = frameFillColor;
            }
          });
      }
    });
  },
  watch: {
    detail: {
      immediate: true,
      handler(detail) {
        // 从详情获取数据
        const {
          name,
          datasetId,
          classId,
          customFieldJson = '{}',
          customFieldsArr = []
        } = detail;
        this.form.name = name;
        this.form.dataclassId = [datasetId, classId];
        this.form.customFields = JSON.parse(customFieldJson);
        this.customFieldsArr = customFieldsArr;
        this.originCoordinates = Array.from(this.coordinates);
        if (classId) {
          this.getCustomFields(classId);
        }
      }
    }
  },
  methods: {
    // 获取自定义字段
    async getCustomFields(dataclassId) {
      // 获取对应自定义属性
      const featureClassFieldPromise = this.$service.data.featureClassField({
        id: dataclassId
      });
      const featureClassStylePromise = this.$service.feature.featureClassStyle({
        id: dataclassId
      });
      const res = await Promise.all([
        featureClassFieldPromise,
        featureClassStylePromise
      ]);

      // 样式
      // 判断是数据类样式还是元素样式
      if (this.detail.styleResourcesId) {
        // 元素样式
        this.form.styleResourcesId = this.detail.styleResourcesId;
        this.fillColor = this.detail.fillColor;
        this.frameFillColor = this.detail.frameFillColor;
      } else {
        // 数据类样式
        let fillColor = res[1].data ? res[1].data.polygonStyle.fillColor : '';
        let frameFillColor = res[1].data
          ? res[1].data.polygonStyle.frameFillColor
          : '';
        this.fillColor = fillColor;
        this.frameFillColor = frameFillColor;
        // 更新图标样式
        this.$emit('updatePolygon', fillColor);
      }

      const customFieldsData = res[0].data.data;
      // 自定义字段数据
      this.customFieldsArr = customFieldsData;
      // 表单里自定义字段
      let customFields = {};
      // 添加时保存的数据
      const detailCustomField = this.detail.customFieldJson
        ? JSON.parse(this.detail.customFieldJson)
        : {};
      // 自定义字段校验规则
      let customFieldsRules = {};
      // 表单form添加对应字段、和校验规则
      for (let i = 0; i < customFieldsData.length; i++) {
        const { id, name, fieldType, maxLength, notNull } = customFieldsData[i];
        customFields[id] = detailCustomField[id];
        customFieldsRules[id] = [];
        // 是否必填
        if (notNull) {
          customFieldsRules[id].push({
            required: true,
            message: `请输入${name}`,
            trigger: 'blur'
          });
        }
        // 最大长度、且不为数字时
        if (maxLength && fieldType !== 2) {
          customFieldsRules[id].push({
            max: maxLength,
            message: `${name}最长${maxLength}个字符`,
            trigger: 'blur'
          });
        }
        switch (fieldType) {
          // 数字
          case 2:
            customFieldsRules[id].push(numberVerify());
            break;
          // 网址
          case 4:
            customFieldsRules[id].push(websiteVerify());
            break;
          // 电话
          case 5:
            customFieldsRules[id].push(phoneVerify());
            break;
        }
      }
      this.form.customFields = customFields;
      this.rules.customFields = customFieldsRules;
    },
    // 选择数据类
    handleDataclassSelectChange(val) {
      // 获取对应自定义属性
      this.getCustomFields(val[1]);
    },
    // 打开样式面板
    hanldeStyleEdit() {
      const data = {
        status: true,
        type: 'polygon',
        text: '地图数据样式编辑'
      };
      this.$store.commit('data/setDataclassAttrSytleEditDialog', data);
    },
    // 更新数据集数据类
    updateDataclass(data) {
      const { datasetId, dataclassId } = data;
      if (datasetId && dataclassId) {
        this.form.dataclassId = [datasetId, dataclassId];
      }
      if (!datasetId && dataclassId) {
        this.form.dataclassId = [this.form.dataclassId[0], dataclassId];
      }
      // 更新自定义字段
      this.getCustomFields(dataclassId);
    },
    // 进入编辑状态
    edit() {
      const state = 'edit';
      this.$emit('stateChange', state);
      const { classId } = this.detail;
      // 自定义字段
      this.getCustomFields(classId);
    },
    // 确定
    confirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { name, dataclassId, styleResourcesId, customFields } =
            this.form;
          const { datasetName, dataclassName } = this.$refs.dataclassSelect;
          let polygonPoints = this.coordinates.map(coordinate => {
            return {
              x: coordinate[0],
              y: coordinate[1],
              z: coordinate[2]
            };
          });
          polygonPoints.push(polygonPoints[0]);
          // 多边形信息
          let info = {
            polygonJson: JSON.stringify(polygonPoints),
            name,
            classId: dataclassId[1],
            styleResourcesId,
            customFieldJson: JSON.stringify(customFields)
          };
          let req = '';
          switch (this.state) {
            case 'add':
              req = this.$service.feature.polygonAdd;
              break;
            case 'edit':
              req = this.$service.feature.polygonEdit;
              info.id = this.detail.id;
              break;
          }
          req(info).then(res => {
            const { status, data, msg } = res;
            if (status === 200) {
              let otherData = {
                fillColor: this.fillColor,
                frameFillColor: this.frameFillColor,
                datasetId: dataclassId[0],
                datasetName,
                dataclassName
              };
              // 添加时
              if (this.state === 'add') {
                otherData.id = data.id;
              }
              // 编辑时
              if (this.state === 'edit') {
                otherData.layerId = this.detail.layerId;
              }
              // 刷新属性表
              if (
                this.$store.state.data.dataclassAttrSheetPanelVisible.status
              ) {
                $bus.$emit('refreshAttrList');
              }
              this.$emit('confirm', Object.assign({}, info, otherData));
              this.$emit('close');
              this.$message.success(msg);
            }
          });
        }
      });
    },
    // 取消
    cancel() {
      if (this.state === 'add') {
        this.$emit('clear');
      }
      // 还原样式
      if (this.state === 'edit') {
        this.$emit('updatePolygon', this.detail.fillColor);
        this.$parent.currentFeature.coordinates = this.originCoordinates;
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.form-btn-group {
  margin-top: 0 !important;
}

// 自定义字段
.customField-form-item {
  /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
  }
  .form-label {
    width: 0;
    flex: 1;
  }
}

.form {
  // 表单label
  &-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      margin-right: 8px;
    }
    &-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  // 表单content
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 40px;
    &.style-content {
      min-height: 40px;
    }

    &-left {
      display: flex;
      align-items: center;

      button {
        margin-right: 30px;
      }
    }

    &-right {
      display: flex;
    }

    .coord-ipt,
    .height-ipt {
      width: 150px;
    }

    .img-wrap {
      padding: 10px;
      background: #eaeefe;
      border-radius: 8px;

      // 样式预览
      // 多边形
      .style-polygon {
        &-box {
          width: 200px;
          height: 100px;
          background: #ffffff;
          border: 1px solid #507cff;
          border-radius: 4px;
          // 样式预览
          &-content {
            box-sizing: border-box;
            height: 100%;
            padding: 5px;
            background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
            .item-width {
              display: flex;
              width: 19px;
              height: 9px;
            }
            .item-height {
              display: flex;
              width: 9px;
              height: 19px;
            }
            &-top {
              display: flex;
            }
            &-right {
              display: flex;
              flex-direction: column;
            }
            &-bottom {
              display: flex;
              flex-direction: row-reverse;
            }
            &-left {
              display: flex;
              flex-direction: column-reverse;
            }
            &-box {
              height: 69px;
              display: flex;
              justify-content: space-between;
            }
            &-color {
              flex: 1;
              height: 100%;
            }
          }
        }
      }
    }

    // 编辑按钮
    .editBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      &.confirm {
        background: #507cff;
        margin-right: 14px;
      }
      &.cancel {
        background: #d6e0ff;
      }
    }
  }
}
</style>
