<template>
  <div :id="id">
    <slot></slot>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import { longmapPoint, longmapColor } from './Tool';
export default {
  name: 'LongMap',
  provide() {
    return {
      getMap: this.getMap
    };
  },
  props: {
    id: String,
    // 地图中心点
    center: {
      type: Array,
      required: true
    },
    backgroundColor: {
      type: Array,
      default: () => {
        return ['#16242D', 1];
      }
    },
    // 是否能缩放
    zoom: {
      type: Boolean,
      default: true
    },
    // 是否能旋转
    rotate: {
      type: Boolean,
      default: true
    },
    // 是否能平移
    pan: {
      type: Boolean,
      default: true
    },
    // 是否操作
    enabled: {
      type: Boolean,
      default: true
    }, //
    followMap: [Object, Array, String] // 双屏对比，map对象
  },
  mounted() {
    let vm = this;
    const { id, center, backgroundColor, zoom, rotate, pan, enabled } = this;
    let map = new window.LongMap.Map(id);
    if (typeof map !== 'object') {
      return alert('该设备或浏览器不持之webgl，请更换设备或浏览器');
    }
    this.map = map;
    map.globe._globe.material.uniforms.u_color.value =
      longmapColor(backgroundColor);
    // 设置中心点
    map.setCenter(longmapPoint(center), center[2]);
    // 设置缩放
    map.enableZoom = zoom;
    // 设置旋转
    map.enableRotate = rotate;
    // 设置平移
    map.enablePan = pan;
    // 设置能否操作
    map.enabled = enabled;

    // 双屏对比
    if (this.followMap) {
      this.follow(this.followMap);
    }

    // 点击事件
    map.addEventListener('click', function (e) {
      vm.$emit('click', e);
    });
    // 视角改变
    map.addEventListener('change', function (e) {
      vm.$emit('change', e);
    });
    // 鼠标移动
    map.addEventListener('mousedown', function (e) {
      vm.$emit('mousemove', e);
    });
    // 鼠标移动
    map.addEventListener('mousemove', function (e) {
      vm.$emit('mousemove', e);
    });
    // 鼠标移动
    map.addEventListener('mouseup', function (e) {
      vm.$emit('mousemove', e);
    });

    const ro = new ResizeObserver(entries => {
      entries.forEach(() => {
        // 修改尺寸
        map.updateView();
      });
    });
    // 监听容器尺寸变化，更新坐标
    ro.observe(map.container);
  },
  methods: {
    // 双屏
    follow(followMap) {
      // 判断followMap是对象还是数组
      const type = Object.prototype.toString.call(followMap);
      let mapsVm = [];
      // 将数据统一成数组
      if (type === '[object Object]') {
        mapsVm = [followMap];
      } else if (type === '[object Array]') {
        mapsVm = followMap;
      }
      // 获取map对象
      let allMapPromise = mapsVm.map(mapVm => mapVm.getMap());
      Promise.all(allMapPromise).then(maps => {
        this.map.follow(maps);
        // 设置地图中心点
        const view = maps[0].getViewAngle();
        this.map.setViewAngle({
          duration: 0,
          ...view
        });
      });
    },
    // 获取实例化地图
    getMap() {
      return new Promise(resolve => {
        let map = this.map;
        if (map) {
          resolve(map);
        } else {
          // 定时去获取map,获取到map后清除定时器
          let timer = setInterval(() => {
            let map = this.map;
            if (map) {
              resolve(map);
              clearInterval(timer);
            }
          }, 500);
        }
      });
    }
  },
  watch: {
    // 双屏对比
    followMap(followMap) {
      if (followMap) {
        this.follow(followMap);
      }
    },
    // 中心点
    center(center) {
      if (!this.map) {
        throw new Error('地图未初始化');
      }
      this.map.setCenter(longmapPoint(center), center[2]);
    },
    // 缩放
    zoom(zoom) {
      if (!this.map) {
        throw new Error('地图未初始化');
      }
      this.map.enableZoom = zoom;
    },
    // 旋转
    rotate(rotate) {
      if (!this.map) {
        throw new Error('地图未初始化');
      }
      this.map.enableRotate = rotate;
    },
    // 平移
    pan(pan) {
      if (!this.map) {
        throw new Error('地图未初始化');
      }
      this.map.enablePan = pan;
    },
    // 操作
    enabled(enabled) {
      if (!this.map) {
        throw new Error('地图未初始化');
      }
      this.map.enabled = enabled;
    }
  }
};
</script>
