<template>
  <div class="longmap-text"></div>
</template>

<script>
import { longmapPoint, longmapColor } from './Tool';
export default {
  name: 'LongMapText',
  inject: ['getLayer'],
  props: {
    // 文字
    text: {
      type: String,
      required: true
    },
    // 坐标
    coordinates: {
      type: Array,
      required: true
    },
    // 字号
    fontSize: {
      type: Number,
      default: 14
    },
    color: {
      type: Array,
      default: () => ['#ff0000']
    },
    backgroundColor: [Array, String],
    padding: {
      type: Array,
      default: () => [5, 5, 5, 5]
    },
    radius: {
      type: Number,
      default: 0
    },
    // 锚点
    offset: {
      type: Object,
      default: () => {
        return {
          x: 0.5,
          y: 0
        };
      }
    },
    // 显示还是隐藏
    show: {
      type: Boolean,
      default: true
    },
    featureData: Object // 自定义数据
  },
  // 组件挂载后，添加元素
  mounted() {
    this.getLayer().then(layer => {
      const {
        text,
        coordinates,
        fontSize,
        color,
        backgroundColor,
        padding,
        radius,
        offset,
        show,
        featureData
      } = this;
      let feature = new window.LongMap.TextMarker({
        text,
        position: longmapPoint(coordinates),
        style: {
          fontSize,
          fontColor: longmapColor(color),
          backgroundColor: backgroundColor ? longmapColor(backgroundColor) : '',
          padding,
          radius
        },
        offset
      });
      feature.featureData = featureData;
      this.feature = feature;
      layer.add(feature);
      // 是否隐藏
      if (!show) {
        feature.show = false;
      }
    });
  },
  // 组件销毁后，移除元素
  destroyed() {
    this.feature && this.feature.remove();
  },
  methods: {
    setStyle() {
      if (!this.feature) return;
      const { fontSize, color, backgroundColor, padding, radius } = this;
      this.feature.setStyle({
        fontSize,
        fontColor: longmapColor(color),
        backgroundColor: backgroundColor ? longmapColor(backgroundColor) : '',
        padding,
        radius
      });
    }
  },
  // 监听属性变化
  watch: {
    coordinates(coordinates) {
      this.feature && this.feature.setPosition(longmapPoint(coordinates));
    },
    text(text) {
      this.feature && this.feature.setText(text);
    },
    fontSize() {
      this.setStyle();
    },
    color() {
      this.setStyle();
    },
    backgroundColor() {
      this.setStyle();
    },
    show(show) {
      if (!this.feature) return;
      if (show) {
        this.feature.show = true;
      } else {
        this.feature.show = false;
      }
    }
  }
};
</script>
